<template>
  <div>
    <div class="container">
      <div
        class="card mt-4"
        style="max-width: 800px; margin: 0 auto !important"
      >
        <div class="card-body">
          <Filtros
            :title="`Vendas`"
            @filtrar="filtrar"
            :initial="filtros"
          >
            <div class="row pb-2">
              <div class="col-12 col-sm-4 text-left pr-2">
                <label style="margin-bottom: 7px"
                  ><i class="fa fa-credit-card mr-1" /> Forma de
                  Pagamento</label
                >
                <v-select
                  :options="listaFormas"
                  label="forma"
                  multiple
                  v-model="filtros.formas"
                />
              </div>
              <div class="col-12 col-sm-4 text-left px-2">
                <label style="margin-bottom: 7px"
                  ><i class="fa fa-layer-group mr-1" /> Agrupamentos
                </label>
                <v-select
                  :options="[
                    'Especialidade',
                    'Médico',
                    'Tipo Consulta',
                    'Cliente',
                    'Mês',
                    'Dia',
                    'Clínica',
                  ]"
                  multiple
                  v-model="filtros.agrupamentos"
                />
              </div>
              <div class="col-12 col-sm-4 text-left pt-4 text-center">
                <b-checkbox
                  switch
                  v-model="filtros.exibeDetalhe"
                  >Exibir Detalhes</b-checkbox
                >
              </div>
            </div>
          </Filtros>
        </div>
      </div>
      <div
        class="p-5 text-center"
        v-if="loading"
      >
        <b-spinner></b-spinner>
        <br />
        carregando...
      </div>
      <div v-if="!loading && lista && lista.length > 0">
        <div class="text-right">
          <b-btn
            variant="none"
            @click="imp"
            ><b-icon-printer /> Imprimir</b-btn
          >
        </div>
        <div
          class="col-12"
          v-if="lista && lista.length > 0"
          id="printDIV"
        >
          <print-component
            :printNow="true"
            :show="true"
            :titulo="`Relatório de Vendas`"
            :filtros="filtroTexto"
            :orientationPDF="'landscape'"
          >
            <div
              class="text-left"
              v-if="filtros.agrupamentos && filtros.agrupamentos.length > 0"
            >
              <div
                v-for="(gp, idxGp) in lista"
                :key="idxGp"
              >
                <venda-grupos
                  :grupo="gp"
                  :filtros="filtros"
                ></venda-grupos>
              </div>
            </div>
            <div v-else>
              <venda-detalhes :detalhes="lista"></venda-detalhes>
            </div>
          </print-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintComponent from "../../components/Print/PrintComponent.vue";
import Filtros from "../../components/Relatorios/Filtros.vue";
import moment from "moment";
import RelatoriosLib from "../../libs/RelatoriosLib";
import VendaGrupos from "../../components/Relatorios/Vendas/VendaGrupos.vue";
import VendaDetalhes from '../../components/Relatorios/Vendas/VendaDetalhes.vue';
// import EntidadesLib from "../../libs/EntidadesLib";

export default {
  components: {
    Filtros,
    PrintComponent,
    VendaGrupos,
    VendaDetalhes,
  },
  props: {},
  mounted() {},
  data() {
    return {
      loading: false,
      filtros: {
        formas: [],
        agrupamentos: [],
        exibeDetalhe: true,
      },
      listaFormas: [
        {
          forma: "DEF",
        },
        {
          forma: "Dinheiro",
        },
        {
          forma: "Cartão de Crédito",
        },
        {
          fomformara: "Cartão de Débito",
        },
        {
          forma: "PIX",
        },
      ],
      lista: [],
      filtroTexto: "",
    };
  },
  watch: {},
  computed: {
    gpKeys() {
      return this.agrupamentos.map((it) => {
        if (it == "Mês") return "mes";
        if (it == "Dia") return "dia";
        if (it == "Clínica") return "sigla";
        if (it == "Especialidade") return "especialidade";
        if (it == "Médico") return "medico";
        if (it == "Tipo Consulta") return "tipo";
        if (it == "Cliente") return "paciente";

        return it;
      });
    },
  },
  methods: {
    async filtrar(filtros) {
      this.filtros = filtros;
      this.loading = true;
      console.log("filtros", this.filtros);
      this.filtroTexto = `Periodo ${moment(this.filtros.periodo.dt_i).format(
        "DD/MM/YYYY"
      )} até ${moment(this.filtros.periodo.dt_f).format("DD/MM/YYYY")}`;
      this.lista = [];

      try {
        let result = await RelatoriosLib.getRelatorioVendas(this.filtros);
        console.log("result", result);
        if (result && result.success) {
          this.lista = result.data;
        }
      } catch (error) {
        console.log("erro on load getRelatoriosVendas", error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
