var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'text-left p-5 container-fluid': !_vm.onModal }},[_c('div',[_c('b-form-input',{attrs:{"placeholder":"Digite para buscar","reduce":"900"},model:{value:(_vm.filtrarDetalhe),callback:function ($$v) {_vm.filtrarDetalhe=$$v},expression:"filtrarDetalhe"}}),(_vm.detFilter)?_c('div',{staticClass:"d-flex mt-3 justify-content-"},[_c('b-btn',{staticStyle:{"height":"40px"},on:{"click":_vm.voltarFiltroDet}},[_vm._v(" Voltar ")]),(_vm.capituloSelecionado)?_c('div',{staticClass:"ml-5 pl-5",staticStyle:{"border-left":"1px solid #ccc"}},[_c('h3',[_vm._v("Capítulo "+_vm._s(_vm.convertToRoman(_vm.capituloSelecionado.idx + 1)))]),_c('p',[_vm._v(" "+_vm._s(_vm.capituloSelecionado.descricao)),_c('br'),_c('small',[_vm._v("Grupo entre "+_vm._s(_vm.capituloSelecionado.cat_inicio)+" a "+_vm._s(_vm.capituloSelecionado.cat_fim))])])]):_vm._e(),(_vm.detFilter == 'subgrupo' && _vm.grupoSelecionado)?_c('div',{staticClass:"ml-5 pl-5",staticStyle:{"border-left":"1px solid #ccc"}},[_c('h3',[_vm._v(" Grupo entre "+_vm._s(_vm.grupoSelecionado.cat_inicio)+" a "+_vm._s(_vm.grupoSelecionado.cat_fim)+" ")]),_c('p',[_vm._v(_vm._s(_vm.grupoSelecionado.descricao)),_c('br')])]):_vm._e()],1):_vm._e(),(_vm.loadingDet)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Carregando..."}})],1):_vm._e(),(
        !_vm.filtrarDetalhe &&
        _vm.filtrarDetalhe.length <= 0 &&
        (!_vm.detFilter || _vm.detFilter == '')
      )?_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"row"},_vm._l((_vm.capitulos),function(cap,idx){return _c('div',{key:idx,staticClass:"col-12 col-sm-6 p-3"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.selecionarCapitulo(cap)}}},[_c('h3',[_vm._v("CAPITULO "+_vm._s(_vm.convertToRoman(idx + 1)))])]),_vm._v(" "+_vm._s(cap.descricao)+" ")])}),0)]):_vm._e(),(
        !_vm.filtrarDetalhe && _vm.filtrarDetalhe.length <= 0 && _vm.detFilter == 'grupos'
      )?_c('div',{staticClass:"p-5"},[_c('div',{staticClass:"row"},_vm._l((_vm.grupos),function(grp,idx){return _c('div',{key:idx,staticClass:"col-12 col-sm-6 p-3"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.selecionarGrupo(grp)}}},[_c('h3',[_vm._v("Grupo entre "+_vm._s(grp.cat_inicio)+" a "+_vm._s(grp.cat_fim))])]),_vm._v(" "+_vm._s(grp.descricao)+" ")])}),0)]):_vm._e(),(
        (_vm.filtrarDetalhe && _vm.filtrarDetalhe.length > 0) ||
        (_vm.listaCids && _vm.listaCids.length > 0)
      )?_c('div',{staticClass:"p-5"},[_c('h3',[_vm._v("Lista de CIDs")]),_c('b-table',{ref:"lstCID",attrs:{"selectable":_vm.selectable,"hover":"","items":_vm.listaCids},on:{"row-selected":_vm.select}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }