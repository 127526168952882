<template>
  <div>
    <div
      class="row"
      v-if="loading"
    >
      <div class="col-12">
        <div class="mt-1 p-1 text-center">
          <b-spinner small /> <br />
          Carregando....
        </div>
      </div>
    </div>

    <div
      v-if="
        horario &&
        !user.isMedico &&
        (!horario.paciente || !horario.id_paciente || !pacienteCompareceu)
      "
    >
      <div class="row mb-3">
        <div class="col-12">
          <label>Selecione o cadastro do paciente</label>

          <select-paciente
            @paciente="selecionarPaciente"
            :selecionado="idPaciente"
            @edit="edit"
            :text="textPaciente"
            @new="onNovo"
            :tipo="['paciente']"
          />
          <small
            class="text-muted"
            v-if="!idPaciente && horario && horario.paciente"
          >
            *Vincule o paciente
            <a @click="setText(horario.paciente)">
              <b>{{
                horario && horario.paciente ? horario.paciente : ""
              }}</b></a
            >
            ao nosso sistema para continuar
          </small>
          <label v-if="hasPaciente">
            <small>
              <small>
                ID: {{ pacienteSelecionado.id }} | Nome:
                {{ pacienteSelecionado.razao_social }}
              </small>
            </small>
          </label>
        </div>
        <!-- <div class="col-12 mt-2">
          <venda :horario="horario" :paciente="pacienteSelecionado" />
      </div> -->
        <!-- <div class="col-12 mt-3">
        <b-button block variant="primary" @click="marcarCompareceu">
          <b-icon-check /> COMPARECEU
        </b-button>
      </div> -->
      </div>
      <button-atendimento @click="onAction('lancarVenda')">
        <h2 class="text-muted text-center">Lançar Venda</h2></button-atendimento
      >
      <button-atendimento
        class="mt-3"
        @click="onAction('vincularVenda')"
      >
        <h2 class="text-muted text-center">Vincular Venda</h2>
      </button-atendimento>
      <button-atendimento
        class="mt-3"
        @click="onAction('continuarSemVenda')"
      >
        <h2 class="text-muted text-center">
          Continuar sem Venda
        </h2></button-atendimento
      >
    </div>

    <div v-if="pacienteCompareceu && hasPaciente">
      <div
        class="col-12"
        v-if="user.isMedico"
      >

        <atendimento
          :paciente_id="pacienteSelecionado.id"
          :horario="horario"
          @close="close"
          @iniciarAtedimento="iniciarAtedimento"
          @finalizarAtedimento="finalizarAtedimento"
        />
      </div>
      <div v-else>
        <div class="row">
          <div class="col text-left">
            <div class="d-flex justify-content-between bg-light py-3">
              <div class="d-flex">
                <b-btn
                  @click="openModal(pacienteSelecionado)"
                  class="ml-2 mr-2"
                  size="lg"
                  large
                  variant="transparent"
                >
                  <i
                    class="fa fa-id-card-alt"
                    style="font-size: 30px; color: #555"
                /></b-btn>
                <h1 class="text-muted">
                  {{ pacienteSelecionado.razao_social }}
                </h1>
              </div>
              <div v-if="horario && horario.anamnese && horario.anamnese.id">
                <b-btn
                  @click="openAnamnese"
                  :class="{
                    'text-success': horario && horario.anamnese && horario.anamnese.dt_respondeu_anamnese,
                    'text-danger': horario && horario.anamnese && !horario.anamnese.dt_respondeu_anamnese,
                  }"
                  size=""
                  large
                  variant="transparent"
                  block
                >
                  <i
                    class="fa fa-file-medical-alt block"
                    style="font-size: 30px"
                  /><br />
                  Ananmnse
                  {{
                    horario && horario.anamnese && !horario.anamnese.dt_respondeu_anamnese
                      ? "Pendente"
                      : "Respondida"
                  }}
                </b-btn>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <b-card>
                  <b-card-text>
                    <div><b>ID:</b> {{ horario.id }}</div>
                    <div>
                      <b><i class="fa fa-clock"></i> </b>
                      {{ horario.horario }}
                    </div>
                    <div>
                      <b><i class="fa fa-user-md"></i> </b>
                      {{ horario.MedicoNome }}
                    </div>
                    <div>
                      <b> <i class="fa fa-stethoscope ml-0" /> </b>
                      {{ horario.especialidade }}
                    </div>
                  </b-card-text>
                </b-card>
              </div>
              <div class="col">
                <b-card class="text-center h-100">
                  <b-card-title>
                    <b-icon-info-circle class="mr-1"></b-icon-info-circle
                    >{{ horario.at_status }}
                  </b-card-title>
                  <b-card-text>
                    <h1
                      class="text-center"
                      v-if="horario.at_status == 'FILA DE ESPERA'"
                    >
                      #{{ horario.at_ordem }}
                    </h1>
                    <div v-if="horario.at_inicio && horario.at_termino">
                      {{ horario.at_termino | moment("DD/MM/YYYY HH:mm:ss") }}
                    </div>
                    <div v-if="horario.at_inicio && !horario.at_termino">
                      {{ horario.at_inicio | moment("DD/MM/YYYY HH:mm:ss") }}

                      <br />
                      <b-icon-clock /> {{ tempo }}
                      <b-btn
                        @click="finalizarAtedimento(horario)"
                        size="sm"
                        small
                        variant="danger"
                        class="ml-1"
                        style="font-size: 9px"
                      >
                        Parar</b-btn
                      >
                    </div>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div
            class="col pr-1"
            v-if="horario.at_status != 'FINALIZADO'"
          >
            <button-atendimento
              @click="
                setAtInicio();
                concluirAtendimento(horario);
              "
              stacked
            >
              <i class="fas fa-check-circle"></i>
              Finalizar Atendimento
            </button-atendimento>
          </div>
          <div
            class="col px-1"
            v-if="horario.at_status != 'FINALIZADO'"
          >
            <button-atendimento
              @click="finalizarComVenda(horario)"
              :desabilitado="!canChange"
              stacked
            >
              <i class="fa fa-shopping-cart" /> Nova Venda
            </button-atendimento>
          </div>
          <div
            class="col"
            :class="{ 'pl-1': horario.at_status != 'FINALIZADO' }"
          >
            <button-atendimento
              @click="openLancarReceita"
              :desabilitado="!canChange"
              stacked
            >
              <i class="fas fa-signature"></i> Lançar Receita
            </button-atendimento>
          </div>
          <!-- <div
            class="col pl-1"
            v-if="
              horario &&
              horario.at_inicio &&
              horario.at_status != 'FINALIZADO' &&
              horario.at_termino
            "
          >
            <button-atendimento
              @click="finalizarComVenda(horario)"
              :desabilitado="!canChange"
              stacked
            >
              <i class="fa fa-shopping-cart" /> Finalizar e nova venda
            </button-atendimento>
          </div> -->
        </div>
        <!-- <div
          class="row"
          v-if="
            horario && horario.at_inicio && horario.at_status != 'FINALIZADO'
          "
        >
          <div class="col">
            <button-atendimento
              v-if="horario.at_termino"
              class="mt-2"
              @click="concluirAtendimento(horario)"
              :desabilitado="!canChange"
            >
              <i class="fas fa-check-circle"></i> Concluír
            </button-atendimento>
          </div>
          <div class="col"></div>
        </div> -->

        <div class="row">
          <div class="col text-center">
            <div></div>

            <div v-if="horario.venda_id">
              <button-atendimento
                class="mt-3"
                @click="openVendaDetail"
                :desabilitado="!canChange"
              >
                <i class="fas fa-shopping-basket"></i> Visualizar Venda(s)
              </button-atendimento>
            </div>

            <div class="d-flex justify-content-center mt-3">
              <button-atendimento
                variant="secondary"
                v-if="horario.at_status != 'PENDENTE'"
                @click="voltar(horario)"
                :desabilitado="!canChange"
              >
                <i class="fas fa-history"></i> Etapa Anterior
              </button-atendimento>
              <button-atendimento
                variant="danger"
                class="ml-2"
                @click="close"
              >
                <i class="fas fa-ban"></i> Fechar
              </button-atendimento>

              <!-- <b-btn class="mr-2" variant="danger" @click="close"
                ><i class="fas fa-ban"></i> Cancelar</b-btn
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-paciente-form333"
      size="lg"
      modal-class="modalMaiorFull"
      body-class="modalNoPadding"
      hide-footer
      hide-header
      @close="closeModal"
    >
      <paciente-ficha
        closeOnSave
        :horario="horario"
        :prePaciente="prePaciente"
        :paciente="pacienteSelecionado"
        :paciente_id="pacienteSelecionado?pacienteSelecionado.id:null"
        @close="closeModal($event)"
      />
    </b-modal>
    <b-modal
      size="lg"
      id="modal-venda1234"
      no-enforce-focus
      hide-footer
      hide-header
      @close="closeVenda"
    >
      <venda
        @concluido="onVenda"
        :horario="horario"
        :paciente="pacienteSelecionado"
        @cancelar="closeVenda"
      />
    </b-modal>

    <b-modal
      id="modal-vincular-venda12411"
      size="xl"
      no-enforce-focus
      hide-footer
    >
      <template #modal-title>
        <i class="fas fa-shopping-basket"></i> Vincular Venda ao paciente |
        <b>{{ pacienteSelecionado.razao_social }}</b> |
      </template>
      <vincular-venda
        :horario="horario"
        @vinculou="onVinculou"
        :empresa_id="horario ? horario.clinica_id : null"
        @close="closeVincularVenda"
      ></vincular-venda>
    </b-modal>

    <b-modal
      id="modal-venda1234-details"
      size="lg"
      hide-footer
      no-enforce-focus
    >
      <template #modal-title>
        <div>
          <i class="fas fa-shopping-basket"> </i> Vendas Relacionadas ao horário
        </div>
      </template>
      <div class="border rounded shadow px-3">
        <div v-if="vendaId">
          <VendaDetail
            @close="closeVendaDetail"
            ref="vendaDetails"
            @cancelarVenda="cancelarVenda($event)"
            :vendaId="vendaId"
          />
        </div>

        <div
          v-else
          class="text-center d-flex flex-column p-2"
        >
          <div class="justify-content-center py-2">
            <img
              width="100%"
              height="125px"
              :src="require('@/assets/img/undraw_No_data_re_kwbl.svg')"
              alt=""
            />
            <p class="mt-2">Selecione uma venda para visualizar.</p>
          </div>
        </div>
      </div>

      <div class="border px-3 rounded shadow pt-2 mt-3">
        <h4 class="text-center text-muted">
          Selecione uma venda para visualizar.
        </h4>
        <div class="caixa">
          <div class="row px-2">
            <div
              class="col-12 col-lg-12 col-xl-6 p-2"
              v-for="venda of vendas"
              :key="`venda_${venda.id}_${vendas.indexOf(venda)}`"
            >
              <VendaCard
                @input="vendaId = $event.id"
                :value="vendaId"
                :lancamento="venda"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-paciente-receita"
      hide-footer
      size="lg"
    >
      <template #modal-title>
        <i class="fas fa-signature"></i> Lançar receita para
        <b>{{ pacienteSelecionado.razao_social }}</b>
      </template>
      <h4 class="text-muted text-center">Receitas lançadas no atendimento</h4>
      <PacienteReceita
        v-if="horario"
        :horario_id="horario.id"
        :paciente="pacienteSelecionado"
        :medico_id="horario.entidade_id"
        :especialidade_id="horario.especialidade_id"
      />
    </b-modal>
    <b-modal
      id="modal-responder-anamnese"
      hide-footer
      :title="
        'Anamnese' +
        (horario && horario.anamnese && horario.anamnese.modeloAnamnese
          ? ': ' + horario.anamnese.modeloAnamnese
          : '')
      "
      size="lg"
    >
      <anamnese-preecher :anamnese="horario.anamnese" />
    </b-modal>
  </div>
</template>

<script>
import SelectPaciente from "../../../components/common/SelectPaciente.vue";
import moment from "moment";
import PacienteFicha from "../../../components/Entidades/Paciente/PacienteFicha.vue";
import EntidadeLib from "../../../libs/EntidadesLib";
import Venda from "../../../components/Caixas/Vendas/Venda.vue";
import CaixasLib from "../../../libs/CaixasLib";
import VincularVenda from "../../../components/Atendimentos/VincularVenda.vue";
import VendaLib from "../../../libs/VendaLib";
import VendaDetail from "../../../components/Caixas/Vendas/VendaDetail.vue";
import PacienteReceita from "../../../components/Entidades/Paciente/PacienteReceitas/PacienteReceita.vue";
import VendaCard from "../../../components/Atendimentos/VendaCard.vue";
import ButtonAtendimento from "../../../components/common/ButtonAtendimento.vue";
import Atendimento from "./Atendimento.vue";
import AnamnesePreecher from "../../../components/Anamnese/AnamnesePreecher.vue";

export default {
  components: {
    SelectPaciente,
    VendaDetail,
    PacienteFicha,
    Venda,
    VendaCard,
    PacienteReceita,
    VincularVenda,
    ButtonAtendimento,
    Atendimento,
    AnamnesePreecher,
  },
  props: {
    horario: Object,
    horarios: { type: Array, default: () => [] },
    agenda: Object,
  },
  created() {
    this.setPrePaciente();
    this.buscarPaciente();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    console.log("dta", this.horario, this.agenda);
    this.canChange =
      this.user.isAdmin ||
      moment(this.agenda.data).format("YYYY-MM-DD 00:00:00") ===
        moment().format("YYYY-MM-DD 00:00:00");
  },
  data() {
    return {
      pacienteSelecionado: { id: null },
      loading: false,
      tempo: "...",
      vendaId: null,
      vendas: [],
      timer: null,
      textPaciente: "",
      finishWithVenda: false,
      prePaciente: {},
      actions: {
        lancarVenda: async () => {
          // console.log("lancarVenda");
          await this.openVenda();
        },
        vincularVenda: () => {
          // console.log("vincularVenda");
          this.openVincularVenda();
        },
        continuarSemVenda: () => {
          //console.log("continuarSemVenda");
          this.marcarCompareceu();
        },
      },
      user: this.$store.state.auth.user,
      canChange: false,
    };
  },
  watch: {
    horario: function () {
      this.buscarPaciente();
    },
    "horario.at_incio"() {
      if (this.horario && this.horario.at_inicio && !this.horario.at_termino) {
        this.timer = setInterval(this.calculaTempo, 1000);
      }
    },
  },
  computed: {
    pacienteCompareceu() {
      return !!this.horario?.compareceu;
    },
    idPaciente() {
      return this.horario?.id_paciente ? this.horario.id_paciente : null;
    },
    hasPaciente() {
      return this.pacienteSelecionado && this.pacienteSelecionado?.id;
    },
  },
  methods: {
    openAnamnese() {
      if (this.horario && this.horario.anamnese && this.horario.anamnese.id) {
        this.$bvModal.show("modal-responder-anamnese");
      } else {
        this.$swal({
          title: "Atenção",
          text: "Não há anamnese cadastrada para atendimento.",
          icon: "warning",
        });
      }
    },
    async onVenda(venda) {
      await this.$nextTick();
      this.$forceUpdate();
      console.log("chamou onVenda no paciente", venda);
      this.closeVenda();
      this.$emit("venda", venda);
      // if (this.finishWithVenda) {
      //   this.concluirAtendimento(this.horario);
      //   this.finishWithVenda = false;
      // } else {
      //   this.$emit("venda", venda);
      // }
    },
    setText(text) {
      this.textPaciente = text;
    },
    setAtInicio() {
      this.horario.at_inicio = moment().format("YYYY-MM-DD HH:mm:ss");
    },
    setPrePaciente() {
      if (this.horario) {
        this.prePaciente = {
          razao_social: this.horario?.paciente,
          cpf: this.horario?.cpf,
          dt_nascimento: moment()
            .subtract("years", this.horario?.idade || 0)
            .format("YYYY-MM-DD"),
        };
        if (this.horario?.telefone) {
          this.prePaciente.telefones = [
            {
              contato: this.horario?.paciente,
              telefone: this.horario.telefone,
            },
          ];
        }
      }
    },
    async openVendaDetail() {
      this.vendas = (
        await VendaLib.buscarVendas({
          "venda_items.horario_id": this.horario.id,
          "venda_items.agenda_id": this.horario.id_agenda,
        })
      ).map((venda) => {
        return { ...venda, cliente: venda.Cliente, paciente: venda.Cliente };
      });

      this.$bvModal.show("modal-venda1234-details");
    },
    openLancarReceita() {
      this.$bvModal.show("modal-paciente-receita");
    },
    async cancelarVenda(id) {
      try {
        // await this.$swal({
        //   title: "Tem certerza que deseja cancelar a venda?",
        //   text: "Isso não poderá ser desfeito!",
        //   icon: "warning",
        //   showCancelButton: true,
        //   cancelButtonText: "Não",
        //   confirmButtonText: "Sim",
        // }).then(async (re) => {
        // if (re.isConfirmed) {
        const { value: observacao } = await this.$swal.fire({
          title: "Motivo do cancelamento",
          input: "text",
          icon: "warning",
          inputLabel: "Motivo",
          inputPlaceholder: "Digite o motivo",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Continuar",
          inputValidator: (value) => {
            if (!value) {
              return "Por favor, informe o motivo do cancelamento.";
            }
          },
        });
        if (!observacao) return;
        let result = await VendaLib.cancelarVenda(id, observacao);
        if (result) {
          this.showToast("success", "Venda cancelada com sucesso!", 3000);
          this.closeVendaDetail();
        } else {
          this.showToast("error", "Erro ao cancelar a venda!", 3000);
        }
        //   }
        // });
      } catch (error) {
        this.showToast(
          "error",
          "Erro ao cancelar a venda!\n" + error.message,
          3000
        );
      }
    },
    async closeVendaDetail() {
      this.$bvModal.hide("modal-venda1234-details");
    },
    finalizarComVenda(horario) {
      horario;
      this.openVenda();
      this.finishWithVenda = true;

      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    async voltar(horario) {
      console.log("-->", horario, moment(horario.data).isBefore(moment()));
      if (moment(horario.at_inicio).isBefore(moment()) && !this.user.isAdmin) {
        this.$swal({
          title:
            "Você não pode modificar atendimentos que não são do dia atual!",
          icon: "error",
        });
        return;
      }
      let status = {
        "FILA DE ESPERA": "Confirmados",
        "EM ATENDIMENTO": "Aguardando Atendimento",
        "ATENDIMENTO CONCLUIDO": "Em Atendimento",
      };
      let cancelarVenda = false;
      let venda = null;
      if (horario.venda_id) {
        venda = (
          await VendaLib.buscarVendas({
            "vendas.id": horario.venda_id,
          })
        )[0];
        console.log(horario);
        if (venda) {
          await this.$swal({
            title: "ATENÇÃO!",
            html: `<p>Há uma venda relacionada a esse horário, deseja cancelar ela?</p><br/> <h4>Venda</h4> <hr/> <b>Venda Num.:</b> ${
              venda.id
            } <br/><b>Data/Hora:</b> ${moment(venda.data).format(
              "DD/MM/YYYY HH:mm"
            )} <br/><b>Valor Total:</b> ${venda.vl_total} <br/>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonText: "Não cancelar.",
            confirmButtonText: '<i class="fas fa-ban"></i> Sim, cancelar.',
          }).then((re) => {
            if (re.isConfirmed) {
              cancelarVenda = true;
            }
          });
        }
      }
      await this.$swal({
        title: "Tem certerza que deseja voltar o horário?",
        html: `Isso irá voltar o horário para a aba de <b>'${
          status[horario?.at_status]
        }'</b>`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, voltar.",
      }).then(async (re) => {
        if (re.isConfirmed) {
          let continua = true;
          if (cancelarVenda) {
            try {
              await VendaLib.cancelarVenda(
                venda?.id,
                `O usuário ${this.user?.nome} voltou o horário do paciente ${horario?.paciente} e cancelou a venda.`
              );

              this.showToast("success", "Venda cancelada com sucesso!", 3500);
            } catch (error) {
              console.log("Erro ao cancelar venda: ", error);
              await this.$swal({
                title:
                  "Houve um erro ao cancelar a venda, deseja voltar mesmo assim?",
                text: "Isso irá voltar o horário sem cancelar a venda relacioanda ao horário.",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Não",
                confirmButtonText: "Sim, voltar mesmo assim.",
              }).then((re) => {
                if (re.isDismissed) {
                  continua = false;
                  return;
                }
              });
            }
          }

          if (continua) {
            this.$emit("voltarHorario", horario);
          }
        }
      });
    },
    onVinculou(data) {
      // console.log('onVinculou has been called', data);
      this.closeVincularVenda();
      this.$emit("vinculou", data);
    },
    openVincularVenda() {
      this.$bvModal.show("modal-vincular-venda12411");
    },
    closeVincularVenda() {
      this.$bvModal.hide("modal-vincular-venda12411");
    },
    async buscarCaixa() {
      let caixas = await CaixasLib.buscarCaixaControle(
        this.user.empresa_id,
        moment().format("YYYY-MM-DD"),
        null,
        { noClosed: true }
      );
      if (
        Array.isArray(caixas)
          ? caixas.length == 0
          : Object.isObject(caixas)
          ? !caixas?.id
          : false
      ) {
        this.$swal({
          title: "Atenção",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'> <b>Não há nenhum caixa aberto, abra o caixa para conseguir lançar a venda!</b> </span>`,
          icon: "error",
          allowEscapeKey: true,
        });
        return false;
      }
      // this.venda.caixaControle = caixas[0];
      return true;
    },
    async buscarPaciente() {
      if (this.horario?.id_paciente) {
        this.loading = true;
        let res = await EntidadeLib.buscaPorCampo({
          id: this.horario.id_paciente,
          showFull: true,
        });
        if (res && res.length > 0) {
          this.pacienteSelecionado = res[0];
          this.loading = false;
          
        }else{
          this.pacienteSelecionado = null;
          this.loading = false;
          this.horario.id_paciente = null;
        }
        if (
            this.horario &&
            this.horario.at_inicio &&
            !this.horario.at_termino
          ) {
            this.timer = setInterval(this.calculaTempo, 1000);
          }
      }
    },
    closeVenda() {
      this.$bvModal.hide("modal-venda1234");
    },
    async openVenda() {
      // if (await this.buscarCaixa()) {
      this.$bvModal.show("modal-venda1234");
      //}
    },
    async onAction(tipo) {
      if (!this.pacienteSelecionado?.id) {
        this.showToast(
          "error",
          "Selecione um paciente para continuar...",
          4500
        );
        return;
      }

      if (tipo && Object.keys(this.actions).includes(tipo)) {
        await this.actions[tipo]();
      }
    },
    selecionarPaciente(paciente) {
      //console.log("CHEGOU AQUI", paciente)
      if (paciente && paciente?.id) {
        this.pacienteSelecionado = paciente;

        this.horario.id_paciente = paciente.id;
        this.horario.paciente = paciente.razao_social;

        this.$emit("updateHorario", {
          ...this.horario,
          _rowVariant: undefined,
        });
      }
    },
    iniciarAtedimento(horario) {
      // this.horario = horario;
      let minOrdem = Math.min(
        ...this.horarios
          .filter((h) => h.at_status == "FILA DE ESPERA")
          .map((h) => Number(h.at_ordem))
      );
      console.log("minOrdem", minOrdem);
      if (horario.at_ordem > minOrdem) {
        this.$swal({
          title: "Atenção",
          html: `Você está atendendo um paciente fora de ordem, tem certeza que deseja continuar? <small>O próximo paciente está na ordem ${minOrdem}°, você está atendendo o que está na ordem ${horario.at_ordem}°</small>`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        }).then((re) => {
          if (re.isConfirmed) {
            if (re.isConfirmed) {
              this.$emit("iniciarAtedimento", horario);
              if (
                this.horario &&
                this.horario.at_inicio &&
                !this.horario.at_termino
              ) {
                this.timer = setInterval(this.calculaTempo, 1000);
              }
            }
          }
        });
        return;
      }

      this.$swal({
        title: "Tem certeza que deseja iniciar o atendimento?",
        //text: "O paciente já foi atendido?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((re) => {
        if (re.isConfirmed) {
          this.$emit("iniciarAtedimento", horario);
          if (
            this.horario &&
            this.horario.at_inicio &&
            !this.horario.at_termino
          ) {
            this.timer = setInterval(this.calculaTempo, 1000);
          }
        }
      });
    },
    finalizarAtedimento(horario, awnser = true) {
      // this.horario = horario;
      if (awnser) {
        this.$swal({
          title: "Tem certeza que deseja finalizar o atendimento?",
          text: "O paciente já foi atendido?",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        }).then((re) => {
          if (re.isConfirmed) {
            this.$emit("finalizarAtedimento", horario);
            if (this.timer) {
              clearInterval(this.timer);
            }
          }
        });
      } else {
        this.$emit("finalizarAtedimento", horario);
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    },
    concluirAtendimento(horario, venda = {}) {
      // this.horario = horario;
      this.$swal({
        title: "Tem certeza que deseja concluír o atendimento?",
        text: "O atendimento do paciente já foi totalmente concluído?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((re) => {
        if (re.isConfirmed) {
          this.$emit("concluirAtendimento", horario, venda);
        }
      });
    },
    updateHorario(horario) {
      console.log(2);
      this.horario = horario;
      this.$emit("updateHorario", horario);
    },
    marcarCompareceu() {
      this.$swal({
        title: "Tem certerza que deseja continuar sem venda?",
        text: "Este paciente realmente compareceu?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((re) => {
        if (re.isConfirmed) {
          this.horario.compareceu = 1;
          this.horario.dt_compareceu = moment().format("YYYY-MM-DD HH:mm:ss");

          this.$emit("updateHorario", {
            ...this.horario,
            _rowVariant: undefined,
          });
        }
      });
    },
    close(evt) {
      evt;
      if (
        this.user.isMedico &&
        this.horario.at_inicio &&
        !this.horario.at_termino
      ) {
        this.$swal({
          title: "Atenção",
          text: "Você está fechando a ficha de um paciente, deseja finalizar o atendimento ou somente fechar a guia?",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Somente fechar",
          confirmButtonText: "Finalizar o atendimento",
        }).then((re) => {
          if (re.isConfirmed) {
            this.finalizarAtedimento(this.horario, false);
            this.$emit("close");
          } else {
            this.$emit("close");
          }
        });
      } else {
        this.$emit("close");
      }
    },
    calculaTempo() {
      if (this.horario.at_inicio) {
        var diffTime = moment.duration(
          moment(moment()).diff(moment(this.horario.at_inicio))
        );

        this.tempo =
          (diffTime.hours() > 0
            ? (diffTime.hours() < 10 ? "0" : "") + diffTime.hours() + ":"
            : "") +
          (diffTime.minutes() < 10 ? "0" : "") +
          diffTime.minutes() +
          ":" +
          (diffTime.seconds() < 10 ? "0" : "") +
          diffTime.seconds();
        // console.log(1);
      }
    },
    closeModal(paciente) {
      if (paciente) {
        console.log("close modal 1", paciente);
        this.selecionarPaciente(paciente);
      }
      this.$bvModal.hide("modal-paciente-form333");
    },
    async edit(paciente) {
      let res = await EntidadeLib.buscaPorCampo({
        id: paciente.id,
        showFull: true,
      });
      console.log("EDITANDO", res);
      if (res && res.length > 0) {
        this.openModal(res[0]);
      }
    },
    openModal(paciente) {
      this.pacienteSelecionado = paciente;
      this.$bvModal.show("modal-paciente-form333");
      paciente;
    },

    async onNovo() {
      this.Entidade = {
        id: "",
        razao_social: "",
        fantasia: "",
        crm: "",
        ie: "",
        cpf: "",
        email: "",
        enderecos: [],
        telefones: [],
        tipo: "PF",
        status: 1,
        // tipo_pessoa: "PF",
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
          },
        },
        convenio: 0,
        fornecedor: 0,
        laboratorio: 0,
        medico: 0,
        paciente: 1,
      };

      this.openModal(this.Entidade);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/caixa.scss";
.botao-atendimento {
  border: 1px solid rgb(163, 162, 162);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    transform: scale(101%);
    box-shadow: 0px 4px 17px 4px rgba(0, 0, 0, 0.11);
    background-color: rgba(95, 95, 95, 0.05);
  }
}
</style>
