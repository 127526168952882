var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card mt-4",staticStyle:{"max-width":"800px","margin":"0 auto !important"}},[_c('div',{staticClass:"card-body"},[_c('Filtros',{attrs:{"title":`Vendas`,"initial":_vm.filtros},on:{"filtrar":_vm.filtrar}},[_c('div',{staticClass:"row pb-2"},[_c('div',{staticClass:"col-12 col-sm-4 text-left pr-2"},[_c('label',{staticStyle:{"margin-bottom":"7px"}},[_c('i',{staticClass:"fa fa-credit-card mr-1"}),_vm._v(" Forma de Pagamento")]),_c('v-select',{attrs:{"options":_vm.listaFormas,"label":"forma","multiple":""},model:{value:(_vm.filtros.formas),callback:function ($$v) {_vm.$set(_vm.filtros, "formas", $$v)},expression:"filtros.formas"}})],1),_c('div',{staticClass:"col-12 col-sm-4 text-left px-2"},[_c('label',{staticStyle:{"margin-bottom":"7px"}},[_c('i',{staticClass:"fa fa-layer-group mr-1"}),_vm._v(" Agrupamentos ")]),_c('v-select',{attrs:{"options":[
                  'Especialidade',
                  'Médico',
                  'Tipo Consulta',
                  'Cliente',
                  'Mês',
                  'Dia',
                  'Clínica',
                ],"multiple":""},model:{value:(_vm.filtros.agrupamentos),callback:function ($$v) {_vm.$set(_vm.filtros, "agrupamentos", $$v)},expression:"filtros.agrupamentos"}})],1),_c('div',{staticClass:"col-12 col-sm-4 text-left pt-4 text-center"},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.filtros.exibeDetalhe),callback:function ($$v) {_vm.$set(_vm.filtros, "exibeDetalhe", $$v)},expression:"filtros.exibeDetalhe"}},[_vm._v("Exibir Detalhes")])],1)])])],1)]),(_vm.loading)?_c('div',{staticClass:"p-5 text-center"},[_c('b-spinner'),_c('br'),_vm._v(" carregando... ")],1):_vm._e(),(!_vm.loading && _vm.lista && _vm.lista.length > 0)?_c('div',[_c('div',{staticClass:"text-right"},[_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.imp}},[_c('b-icon-printer'),_vm._v(" Imprimir")],1)],1),(_vm.lista && _vm.lista.length > 0)?_c('div',{staticClass:"col-12",attrs:{"id":"printDIV"}},[_c('print-component',{attrs:{"printNow":true,"show":true,"titulo":`Relatório de Vendas`,"filtros":_vm.filtroTexto,"orientationPDF":'landscape'}},[(_vm.filtros.agrupamentos && _vm.filtros.agrupamentos.length > 0)?_c('div',{staticClass:"text-left"},_vm._l((_vm.lista),function(gp,idxGp){return _c('div',{key:idxGp},[_c('venda-grupos',{attrs:{"grupo":gp,"filtros":_vm.filtros}})],1)}),0):_c('div',[_c('venda-detalhes',{attrs:{"detalhes":_vm.lista}})],1)])],1):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }