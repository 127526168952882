import axios from "@/utils/axios";

const getCIDByFilter = async (filters) => {
  try {
    const result = (await axios.post("/cids/get", filters)).data;
    console.log(result);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

const getCapitulosCID = async () => {
  try {
    const result = (await axios.get("/cids/capitulos")).data;
    console.log(result);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

const getGruposCID = async (filters) => {
  try {
    const result = (await axios.post("/cids/grupos", filters)).data;
    console.log(result);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

export default {
  getCIDByFilter,
  getCapitulosCID,
  getGruposCID,
};
