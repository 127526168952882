<template>
  <div
    :class="
      `botao-atendimento ` +
      ($props.desabilitado ? 'botao-atendimento-desabilitado' : '') +
      ($props.stacked ? 'stacked' : '')
    "
    :style="`color:${color}; `"
    @click="
      () => {
        if (!desabilitado) $emit('click');
      }
    "
  >
    <div class="">
      <div class="">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    desabilitado: {
      type: Boolean,
    },
    color: {
      type: [String],
      default: "#666",
    },
    stacked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.botao-atendimento {
  border: 1px solid rgb(163, 162, 162);
  border-radius: 5px;
  font-size: 20px;
  padding: 10px;
  width: 100%;

  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    transform: scale(100.5%);
    box-shadow: 0px 4px 17px 4px rgba(0, 0, 0, 0.11);
    background-color: rgba(95, 95, 95, 0.03);
  }
  &.stacked {
    display: block;
    font-size: 17px;
    i {
      display: block;
      font-size: 30px;
    }
    text-align: center;
  }
}
.botao-atendimento-desabilitado {
  cursor: no-drop !important;
  color: #ccc !important;
  opacity: 0.5 !important;
  &:hover {
    color: #eee !important;
  }
}
</style>
