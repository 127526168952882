<template>
  <div>
    <div class="row">
      <div
        class="col-12 col-lg-3 text-center"
        v-if="user && user.id && !user.isPaciente"
      >
        <div
          class="m-auto"
          id="divQrCode"
        >
          <qr-code
            :text="
              'https://web.saudesocial.net/anamnese/' + codifica(anamnese.id)
            "
            :size="150"
            class="m-auto"
          ></qr-code>
        </div>
        <div
          class="text-left pl-1"
          style="word-break: break-all"
        >
          <hr />
          <a
            :href="
              'https://web.saudesocial.net/anamnese/' + codifica(anamnese.id)
            "
          >
            {{
              "https://web.saudesocial.net/anamnese/" + codifica(anamnese.id)
            }}</a
          >
          <hr />
          <b>Senha: {{ anamnese.paciente_id }}</b>
          <hr />
        </div>
        <b-btn
          variant=""
          class="mt-3"
          @click="sendWhatsapp"
          block
        >
          <i class="fab fa-whatsapp"></i> Enviar Whatsapp
        </b-btn>
      </div>
      <div class="col-12 col-lg-9 text-left">
        <b-card
          class="m-auto"
          v-if="anamnese && anamnese.dt_respondeu_anamnese"
          style="max-width: 500px; margin-bottom: 20px !important"
        >
          <b-card-text>
            <h6 class="text-center">
              <b-icon-check-circle />
              Anamnese respondida em
              {{ anamnese.dt_respondeu_anamnese | moment("DD/MM/YYYY HH:mm") }}
              por
              {{ anamnese.usuario_anamnese }}
            </h6>
          </b-card-text>
        </b-card>
        <b-card
          style="max-width: 500px"
          class="m-auto"
        >
          <b-card-text>
            <div class="row">
              <div
                class="col-12"
                v-for="(q, idx) in anamnese.respostas"
                :key="idx"
              >
                <h4>{{ idx + 1 }} - {{ q.pergunta }}</h4>
                <b-form-group v-if="q.tipo == 1">
                  <b-form-radio-group
                    v-model="q.resposta"
                    :options="q.opcoes"
                  ></b-form-radio-group>
                </b-form-group>
                <b-input
                  v-if="q.tipo == 2"
                  v-model="q.resposta"
                />
                <b-input
                  v-if="q.tipo == 3"
                  v-model="q.resposta"
                  type="number"
                />
                <b-textarea
                  v-if="q.tipo == 4"
                  v-model="q.resposta"
                />
                <b-form-group v-if="q.tipo == 5">
                  <b-form-checkbox-group
                    v-model="q.resposta"
                    :options="q.opcoes"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-textarea
                  v-if="q.obs"
                  v-model="q.observacao"
                />
                <hr />
              </div>
            </div>
          </b-card-text>
          <b-btn
            block
            variant="primary"
            @click="salvarAnamnese"
            :disabled="salvando"
          >
            <b-spinner
              small
              v-if="salvando"
            ></b-spinner>
            Salvar Alteração</b-btn
          >
        </b-card>
      </div>
    </div>
    <b-modal
      id="modal-wpp"
      hide-footer
      title="Enviar anamnese para whatsapp"
    >
      <b-form-group label="Número">
        <vue-simple-suggest
          :suggestion-click="true"
          v-model="wppNumber"
          :list="telefonesDisponiveis"
          :filter-by-query="true"
          ref="select-num-wpp"
          mode="select"
          :controls="{
            selectionUp: [38, 33],
            selectionDown: [40, 34],
            select: [13, 36],
            showList: [40],
            hideList: [27, 35, 13],
          }"
        >
          <input
            class="form-control optional-custom-input"
            v-model="wppNumber"
            v-mask="['(##) ####-####', '(##) #####-####']"
            autocomplete="off"
            :id="`txtField${Math.random()}`"
            :name="`txtField${Math.random()}`"
          />
        </vue-simple-suggest>
        <b-alert
          v-if="wppAlert.show"
          :variant="wppAlert.type"
        >
          {{ wppAlert.message }}
        </b-alert>
        <botoes-salvar-cancelar
          :okText="'Enviar'"
          :okIcon="'fab fa-whatsapp'"
          @salvar="enviarWpp"
          :saving="enviandoWpp"
          @cancelar="() => $bvModal.hide('modal-wpp')"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
// import AnamneseLib from '../../libs/AnamneseLib';
import moment from "moment";
import PacienteAnamnesesLib from "../../libs/PacienteAnamnesesLib";
import BotoesSalvarCancelar from "../common/BotoesSalvarCancelar.vue";
import WhatsappService from "../../utils/WhatsappService";
import EntidadesLib from "../../libs/EntidadesLib";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";

export default {
  components: { BotoesSalvarCancelar, VueSimpleSuggest },
  props: {
    anamnese: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.carregarNumeros();
  },
  data() {
    return {
      salvando: false,
      wppNumber: "",
      telefonesDisponiveis: [],
      enviandoWpp: false,
      wppAlert: {
        show: false,
        type: "success",
        message: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    async carregarNumeros() {
      try {
        let res = await EntidadesLib.get(
          "paciente",
          null,
          this.anamnese.paciente_id
        );
        console.log("recarregarNumeross", res);
        if (res && res.entidades && res.entidades.length > 0) {
          this.telefonesDisponiveis = res.entidades[0].telefones.map(
            (num) => num.telefone
          );
          if (this.telefonesDisponiveis.length > 0)
            this.wppNumber = this.telefonesDisponiveis[0];

          console.log("telefones", this.telefonesDisponiveis);
        }
      } catch (error) {
        console.log("erro ao carregar numeros", error);
      }
    },
    sendWhatsapp() {
      this.$bvModal.show("modal-wpp");
    },
    codifica(texto) {
      const encoder = new TextEncoder();
      const data = encoder.encode(texto);
      return btoa(String.fromCharCode(...data));
    },
    async salvarAnamnese() {
      this.salvando = true;
      try {
        this.anamnese.dt_respondeu_anamnese = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        );
        console.log("user", this.user);
        this.anamnese.id_usuario_anamnese = this.user ? this.user.id : 0;
        this.anamnese.usuario_anamnese = this.user ? this.user.nome : "";
        await PacienteAnamnesesLib.store(
          this.anamnese,
          this.user && this.user.id > 0
        );
        this.$swal({
          title: "Anamnse salva com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("reload");
      } catch (error) {
        this.$swal({
          title: "Erro ao salvar anamnese",
          icon: "error",
          showConfirmButton: false,
        });
      }
      this.salvando = false;
    },
    async enviarWpp() {
      if (!this.wppNumber || this.wppNumber.length < 15) {
        this.wppAlert = {
          show: true,
          type: "danger",
          message: "Número inválido",
        };
        return;
      }
      this.enviandoWpp = true;
      try {
        let res1 = await WhatsappService.enviarTextoSimples(
          this.wppNumber,
          "Olá! Segue abaixo o acesso para realizar o preenchimento da sua anamnese." +
            "\nAcesse o link abaixo ou leia o QRCODE e informe a senha para preencher" +
            "\n\n\nSENHA DE ACESSO: *" +
            this.anamnese.paciente_id +
            "*" +
            "\n\n\n*Atenção*: Não compartilhe essa senha com ninguém.\n*Não responda esta mensagem*"
        );
        let res2 = await WhatsappService.enviarTextoSimples(
          this.wppNumber,
          "https://web.saudesocial.net/anamnese/" +
            this.codifica(this.anamnese.id)
        );

        try {
          let imagem = "";

          //get base64 image from divQrCode
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          let img = document
            .getElementById("divQrCode")
            .getElementsByTagName("img")[0];
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          imagem += canvas.toDataURL("image/png");

          console.log("imagem==>", imagem);
          if (imagem != "" && imagem.length > 0) {
            let res3 = await WhatsappService.enviarImagem(
              this.wppNumber,
              imagem,
              ""
            );
            console.log("imagem enviada!", res3);
          }
        } catch (e) {
          console.log("erro ao enviar imagem", e);
        }

        console.log("ressss", res1, res2);
        this.$swal({
          title: "Mensagem enviada com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        this.$bvModal.hide("modal-wpp");
      } catch (error) {
        this.$swal({
          title: "Erro ao enviar mensagem",
          icon: "error",
          showConfirmButton: false,
        });
      }
      this.enviandoWpp = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  input,
  textarea {
    font-size: 2em;
  }
  input[type="radio"],
  input[type="checkbox"] {
    width: 2em;
    height: 2em;
  }
  button {
    font-size: 25px !important;
  }
}
</style>
