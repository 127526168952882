<template>
  <div>
    <b-table
      :items="detalhes"
      :fields="[
        {
          key: 'id',
          label: 'Venda',
        },
        {
          key: 'paciente',
          label: 'Paciente',
          class: 'text-left',
        },
        {
          key: 'exame',
          label: 'Exame',
          class: 'text-left',
        },
        {
          key: 'vl_total',
          label: 'Valor',
        },
      ]"
      style="font-size: 10px"
      small
    >
      <template #cell(exame)="row">
        <div class="text-left">
          {{ row.item.exame }}
        </div>
      </template>
      <template #cell(paciente)="row">
        <div class="text-left">
          {{ row.item.paciente }}
        </div>
      </template>
      <template #cell(vl_total)="row">
        {{ row.item.vl_total | currency }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    detalhes: Array,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
