<template>
  <div>
    <div
      class="menu d-sm-block"
      :class="{
        'd-none': !$store.state.menuVisivel,
        menuMobile: $store.state.menuVisivel,
      }"
    >
 
      <!-- <div style="color:white">{{top}}</div> -->
      <div class="botao" v-if="$store.state.menuVisivel">
        <a class="btClose" @click="$store.state.menuVisivel = false">
          <i class="fa fa-times" />
        </a>
      </div>
      <!-- <router-link to="/notificacoes" activeClass="selecionado" @click="$store.state.menuVisivel = false">
        <div
          class="notifica"
          v-if="notificacoesNaoLidas && notificacoesNaoLidas > 0"
        >
          {{ notificacoesNaoLidas }}
        </div>
        <b-icon-bell :class="{ringStart:notificacoesNaoLidas && notificacoesNaoLidas > 0}" style="font-size: 40px" />
        Notificações
      </router-link> -->
      <router-link
        v-for="mnu in menu"
        :key="mnu.id"
        :class="{ rtl: mnu.rtl }"
        :to="`/${mnu.link}`"
        @mouseover.native="submenu(mnu, true)"
        @click.native="submenu(mnu, null)"
        :ref="`alink${mnu.nome}`"
        @mouseout.native="submenu(mnu, false)"
        activeClass="selecionado"
     
      >
        <img
          :src="require('@/assets/img/icones/menu/' + mnu.icon)"
          v-if="mnu.iconType == 'png'"
        />
        <i
          :class="mnu.icon"
          v-if="mnu.iconType == 'fa'"
          style="font-size: 25px"
        ></i>
        <div>{{ mnu.nome }}</div>
      </router-link>
      <div
        v-for="mnu in menuChild"
        :key="`_sub${mnu.id}`"
        :class="{ rtl: mnu.rtl }"
        style="top: 0; color: white"
        class="sub-menu-hide"
        :ref="`submnu${mnu.nome}`"
        @mouseover="submenu(mnu, true)"
        @mouseout="submenu(mnu, false)"
      >
        <h5 class="text-left text-white">
          <img
            :src="require('@/assets/img/icones/menu/' + mnu.icon)"
            v-if="mnu.iconType == 'png'"
          />
          <i :class="mnu.icon" v-if="mnu.iconType == 'fa'"></i> {{ mnu.nome }}
       
        </h5>
        <router-link
          v-for="mnu2 in mnu.children"
          :key="mnu.children.indexOf(mnu2)"
          class="subMenuItem"
          :to="`/${mnu2.link}`"
          activeClass="selecionado"
          @click.native="closeSubmenu(mnu)"
        >
          <img
            :src="require('@/assets/img/icones/menu/' + mnu2.icon)"
            v-if="mnu2.iconType == 'png'"
          />
          <i :class="mnu2.icon" v-if="mnu2.iconType == 'fa'"></i>
          <div>
            {{ mnu2.nome.length > 20 ? mnu2.nome.substr(0, 20) : mnu2.nome }}
          </div>
        </router-link>
      </div>
      <b-modal
        body-bg-variant="dark"
        body-text-variant="white"
        hide-footer
        hide-header
        v-for="mnu in menuChild"
        :key="`_sub2${mnu.id}`"
        :id="`_sub2${mnu.id}`"
        :class="{ rtl: mnu.rtl }"
        style="height: 100vh"
        class="sub-menu"
        :ref="`submnu${mnu.nome}`"
      >
        <div class="menu mb-6 pb-5" style="height: 100vh; min-height: 500px; width: 100%">
          <h4 class="text-center">
            <img
              :src="require('@/assets/img/icones/menu/' + mnu.icon)"
              v-if="mnu.iconType == 'png'"
            />
            <i :class="mnu.icon" v-if="mnu.iconType == 'fa'"></i> {{ mnu.nome }}
         
          </h4>
          <router-link
            v-for="mnu2 in mnu.children"
            :key="mnu.children.indexOf(mnu2)"
            class="subMenuItem"
            :to="`/${mnu2.link}`"
            activeClass="selecionado"
            @click.native="$bvModal.hide(`_sub2${mnu.id}`)"
          >
            <img
              :src="require('@/assets/img/icones/menu/' + mnu2.icon)"
              v-if="mnu2.iconType == 'png'"
            />
            <i :class="mnu2.icon" v-if="mnu2.iconType == 'fa'"></i>
            <div>{{ mnu2.nome }}</div>
          </router-link>
          <div  class="w-100 mb-2 py-4 d-block"></div>
          <button
            class="btn btn-primary btn-block"
            style="position: fixed; bottom: 0px"
            @click="$bvModal.hide(`_sub2${mnu.id}`)"
          >
            <i class="fa fa-times"></i> Fechar
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  sockets: {
    connect() {
      // console.log("conectado");
      this.getNots();
    },
    getNotificacoesNaoLidas(data) {
      this.notificacoesNaoLidas = data;
      console.log("RECEBEUUUUUUUUUU", data)
    },
    solicitarNotificacoesNaoLidas() {
      console.log('solicitar nao lidas')
      this.getNots();
    },
  },
  data() {
    return {
      notificacoesNaoLidas: 0,
      menu: [],
      menuChild: this.$store.state.menu.filter(
        (x) =>
          x.exibe &&
          x.children &&
          x.children.length > 0 &&
          (x.permissao.includes(this.$store.state.auth.user.perfil) ||
            x.permissao.includes("*"))
      ),
      menuGrps: this.$store.state.menu.filter(
        (x) =>
          x.exibe &&
          x.children &&
          x.children.length > 0 &&
          (x.permissao.includes(this.$store.state.auth.user.perfil) ||
            x.permissao.includes("*"))
      ),
      top: 0,
    };
  },
  mounted() {
    this.getNots();
    let mn = this.$store.state.menu.filter(
      (x) =>
        x.exibe &&
        (x.permissao.includes(this.$store.state.auth.user.perfil) ||
          x.permissao.includes("*"))
    );
    for (let menu of mn) {
      if (menu.children) {
        menu.children = menu.children.sort((a, b) => {
          return a.nome > b.nome ? 1 : -1;
        });
      }
    }
    this.menu = mn;
  },
  methods: {
    async getNots() {
      this.$socket.emit("notificacoes:getNotificacoesNaoLidas");
    },
    getImgUrl(pet) {
      //var images = require.context(pet);
      //return images();
      return require(pet);
    },
    closeSubmenu(mnu) {
      console.log(1);
      this.$refs[`alink${mnu.nome}`][0].$el.classList.remove("selecionado");
      this.$refs[`submnu${mnu.nome}`][0].classList.remove("sub-menu-visible");
      this.$refs[`submnu${mnu.nome}`][0].classList.remove("sub-menu");
    },
    submenu(mnu, enter) {

      if (window.innerWidth >= 575) {
        if ((!mnu.hideSubmenu) && mnu.children && mnu.children.length > 0) {
          if (enter || enter == null) {
            this.$refs[`alink${mnu.nome}`][0].$el.classList.add("selecionado");
            this.$refs[`submnu${mnu.nome}`][0].classList.add("sub-menu");

            this.top =
              this.$refs[`alink${mnu.nome}`][0].$el.getBoundingClientRect()
                .top / 1.3;
            if (
              this.top +
                this.$refs[`submnu${mnu.nome}`][0].getBoundingClientRect()
                  .height >
              window.innerHeight
            ) {
              this.top = -1;
            }
            this.$refs[`submnu${mnu.nome}`][0].style.cssText =
              this.top > 0
                ? "top: " + this.top + "px !important"
                : "top:auto; bottom:0px !important";
          } else {
            this.$refs[`alink${mnu.nome}`][0].$el.classList.remove(
              "selecionado"
            );

            this.$refs[`submnu${mnu.nome}`][0].classList.remove(
              "sub-menu-visible"
            );
            this.$refs[`submnu${mnu.nome}`][0].classList.remove("sub-menu");
            this.$refs[`submnu${mnu.nome}`][0].classList.add("sub-menu-hide");
          }
        }
      } else if (enter == null) {
        this.$bvModal.show(`_sub2${mnu.id}`);
      }
      if(enter){
        setTimeout(() => {
          
          this.$store.state.menuVisivel = false
        }, 700);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/menu.scss";

.ringStart {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
