<template>
  <div :class="{ 'text-left p-5 container-fluid': !onModal }">
    <div>
      <b-form-input
        v-model="filtrarDetalhe"
        placeholder="Digite para buscar"
        reduce="900"
      />

      <div
        v-if="detFilter"
        class="d-flex mt-3 justify-content-"
      >
        <b-btn
          @click="voltarFiltroDet"
          style="height: 40px"
        >
          Voltar
        </b-btn>

        <div
          v-if="capituloSelecionado"
          class="ml-5 pl-5"
          style="border-left: 1px solid #ccc"
        >
          <h3>Capítulo {{ convertToRoman(capituloSelecionado.idx + 1) }}</h3>
          <p>
            {{ capituloSelecionado.descricao }}<br />
            <small
              >Grupo entre {{ capituloSelecionado.cat_inicio }} a
              {{ capituloSelecionado.cat_fim }}</small
            >
          </p>
        </div>
        <div
          v-if="detFilter == 'subgrupo' && grupoSelecionado"
          class="ml-5 pl-5"
          style="border-left: 1px solid #ccc"
        >
          <h3>
            Grupo entre {{ grupoSelecionado.cat_inicio }} a
            {{ grupoSelecionado.cat_fim }}
          </h3>
          <p>{{ grupoSelecionado.descricao }}<br /></p>
        </div>
      </div>

      <div
        v-if="loadingDet"
        class="text-center"
      >
        <b-spinner label="Carregando..." />
      </div>
      <div
        v-if="
          !filtrarDetalhe &&
          filtrarDetalhe.length <= 0 &&
          (!detFilter || detFilter == '')
        "
        class="p-4"
      >
        <div class="row">
          <div
            class="col-12 col-sm-6 p-3"
            v-for="(cap, idx) in capitulos"
            :key="idx"
          >
            <a
              @click="selecionarCapitulo(cap)"
              href="javascript:void(0)"
              ><h3>CAPITULO {{ convertToRoman(idx + 1) }}</h3></a
            >
            {{ cap.descricao }}
          </div>
        </div>
      </div>
      <div
        v-if="
          !filtrarDetalhe && filtrarDetalhe.length <= 0 && detFilter == 'grupos'
        "
        class="p-5"
      >
        <div class="row">
          <div
            class="col-12 col-sm-6 p-3"
            v-for="(grp, idx) in grupos"
            :key="idx"
          >
            <a
              @click="selecionarGrupo(grp)"
              href="javascript:void(0)"
              ><h3>Grupo entre {{ grp.cat_inicio }} a {{ grp.cat_fim }}</h3></a
            >
            {{ grp.descricao }}
          </div>
        </div>
      </div>
      <div
        v-if="
          (filtrarDetalhe && filtrarDetalhe.length > 0) ||
          (listaCids && listaCids.length > 0)
        "
        class="p-5"
      >
        <h3>Lista de CIDs</h3>
        <b-table
          @row-selected="select"
          :selectable="selectable"
          hover
          :items="listaCids"
          ref="lstCID"
        >
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import CIDLib from "../../libs/CIDLib";
export default {
  components: {},
  props: {
    onModal: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregarCapitulos();
  },
  data() {
    return {
      filtrarDetalhe: "",
      loadingDet: true,
      detFilter: "",
      capitulos: [],
      capituloSelecionado: null,
      grupoSelecionado: null,
      grupos: [],
      categorias: [],
      listaCids: [],
      tmr: null,
    };
  },
  watch: {
    filtrarDetalhe: {
      handler: function (val) {
        clearInterval(this.tmr);
        this.tmr = setTimeout(() => {
          if (val.length >= 2) {
            this.loadingDet = true;
            this.listaCids = [];
            this.detFilter = "";
            this.capituloSelecionado = null;
            this.grupoSelecionado = null;
            CIDLib.getCIDByFilter({ global: val })
              .then((res) => {
                this.listaCids = res;
                this.loadingDet = false;
              })
              .catch((err) => {
                console.error(err);
                this.loadingDet = false;
              });
          }
        }, 500);
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    select(it) {
      console.log("it", it);
      if (it && it.length > 0) {
        this.$emit("select", it[0]);
        this.$refs.lstCID.clearSelected();
      }
    },
    voltarFiltroDet() {
      if (this.capituloSelecionado && !this.grupoSelecionado) {
        this.detFilter = "";
        this.capituloSelecionado = null;
        this.listaCids = [];
      } else if (this.capituloSelecionado && this.grupoSelecionado) {
        this.detFilter = "grupos";
        this.grupoSelecionado = null;
        this.listaCids = [];
      }
    },
    async selecionarGrupo(grp) {
      if (grp) {
        this.loadingDet = true;
        this.grupoSelecionado = grp;
        this.detFilter = "subgrupo";
        this.categorias = [];
        this.listaCids = [];
        try {
          this.listaCids = await CIDLib.getCIDByFilter({
            cat_inicio: grp.cat_inicio,
            cat_fim: grp.cat_fim,
          });
        } catch (error) {
          console.error(error);
        }
      }
      this.loadingDet = false;
    },
    async selecionarCapitulo(cap) {
      if (cap) {
        this.capituloSelecionado = cap;
        this.detFilter = "grupos";
        this.grupos = [];
        this.loadingDet = true;
        this.listaCids = [];

        try {
          this.grupos = (
            await CIDLib.getGruposCID({
              capitulo_id: cap.id,
            })
          ).map((it, idx) => ({
            idx,
            ...it,
          }));
        } catch (error) {
          console.error(error);
          this.loadingDet = false;
        }
        this.loadingDet = false;
      }
    },
    convertToRoman(num) {
      if (typeof num !== "number") return false;

      const digits = String(+num).split("");
      const key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ];
      let roman = "";
      let i = 3;
      while (i--) {
        roman = (key[+digits.pop() + i * 10] || "") + roman;
      }
      return Array(+digits.join("") + 1).join("M") + roman;
    },
    async carregarCapitulos() {
      this.loadingDet = true;
      this.capitulos = [];
      try {
        this.capitulos = (await CIDLib.getCapitulosCID()).map((it, idx) => ({
          idx,
          ...it,
        }));
      } catch (error) {
        console.error(error);
        this.loadingDet = false;
      }
      this.loadingDet = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
