<template>
  <div class="pacienteFicha pb-5 mb-4">
    <div v-if="!loaded">
      <div class="text-center mt-3">
        <b-spinner></b-spinner>
      </div>
    </div>
    <div v-if="EntidadeObj && loaded">
      <div class="pacienteInfo">
        <div class="foto">
          <div
            class="text-center mt-3"
            v-if="loadingFoto"
          >
            <b-spinner></b-spinner>
          </div>
          <img
            :draggable="false"
            class="img-fluid"
            :src="formataUrl()"
            style="cursor: pointer"
            v-if="!loadingFoto"
          />
        </div>
        <div style="flex-grow: 1">
          <div class="nome">
            {{ EntidadeObj.razao_social }}
          </div>
          <div class="infos">
            <div
              class="info"
              v-if="EntidadeObj.dt_nascimento"
            >
              <label>Código</label>
              {{ EntidadeObj.id }}
            </div>
            <div
              class="info"
              v-if="EntidadeObj.dt_nascimento"
            >
              <label>Nascimento</label>
              {{ EntidadeObj.dt_nascimento | moment("DD/MM/YYYY") }}
              ({{ EntidadeObj.idade }})
            </div>
            <div class="info">
              <label>CPF</label>
              {{ EntidadeObj.cpf }}
            </div>
            <div
              class="info"
              v-if="EntidadeObj.telefones && EntidadeObj.telefones.length > 0"
            >
              <label>Telefone</label>
              {{ EntidadeObj.telefones[0].telefone }}
            </div>
          </div>
        </div>

        <div class="fichaClose">
          <a
            href="#"
            class="btClose"
            @click="close"
          >
            <i class="fa fa-times" />
          </a>
        </div>
      </div>
      <hr />
      <b-card>
        <b-card-text>
          <div class="d-flex justify-content-between">
            <div>
              <h4>
                Atendimento #{{ horario.at_ordem.toString().padStart(2, "0") }}
              </h4>
              <div>
                <small>
                  <small>
                    <small>
                      ID:
                      {{ horario.id.toString().padStart(8, "0") }}
                    </small>
                  </small>
                </small>
              </div>
            </div>

            <div>
              <div
                class="info pl-2 ml-2"
                v-if="horario"
              >
                <b-button
                  v-if="!horario.at_inicio"
                  variant="success"
                  @click="() => startAtendimento()"
                  block
                  size="lg"
                  ><i class="fa fa-play" /> Iniciar Atendimento</b-button
                >
                <div
                  v-if="horario.at_inicio && !horario.at_termino"
                  class="d-flex justify-content-between"
                >
                  <div class="pr-4 text-center">
                    <label
                      ><i class="fa fa-clock" /> Atendimento Iniciado</label
                    >
                    <br />
                    {{ horario.at_inicio | moment("DD/MM/YYYY HH:mm:ss") }}
                  </div>
                  <div class="text-right pl-4">
                    <h3><b-icon-alarm /> {{ tempo }}</h3>
                  </div>
                </div>
              </div>
              <div
                class="info pl-2 ml-2"
                v-if="horario && horario.at_inicio && !horario.at_termino"
              >
                <b-button
                  variant="danger"
                  block
                  @click="stopAtendimento"
                  ><i class="fa fa-play" /> Finalizar Atendimento</b-button
                >
              </div>
            </div>
          </div>
        </b-card-text>
      </b-card>
      <b-tabs
        class="mt-4"
        vertical
        pills
        nav-class="bg-light fixonScroll"
      >
        <b-tab>
          <template v-slot:title>
            <b-icon-archive /> Atendimento Geral
          </template>
          <b-tabs>
            <b-tab>
              <template v-slot:title>
                <b-icon-person /> Atendimento Incial
              </template>
              <!---submnu-->
              <div class="py-4 d-flex gap-2 fixonScroll">
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('questionario')"
                  >Questionário</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('anamnese')"
                  >Anamnese</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('queixas')"
                  >Queixas</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('cids')"
                  >CID`s</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('receitas')"
                  >Receitas</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('exames')"
                  >Exames</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('atestados')"
                  >Atestados</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('anexos')"
                  >Anexos</b-btn
                >
                <b-btn
                  size="sm"
                  small
                  variant="outline-primary"
                  @click="scrollTo('analise')"
                  >Análise Final</b-btn
                >
              </div>
              <b-card
                class="mt-2"
                v-if="horario.questionario"
              >
                <b-card-title>Questionário de Agendamento</b-card-title>
                <b-card-text class="pl-4">
                  <b-row>
                    <b-col
                      v-for="(item, index) in Object.keys(horario.questionario)"
                      :key="index"
                      cols="12"
                    >
                      <b>{{ convertCamelCaseToTitleCase(item) }}:</b>
                      {{ horario.questionario[item] }}
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
              <div id="questionario"></div>

              <b-card class="my-4">
                <b-card-title class="d-flex justify-content-between">
                  <div>Anamnese</div>
                </b-card-title>
                <b-card-text>
                  <b-btn
                    variant="info"
                    class="m-auto"
                    block
                    @click="showAnamnese = true"
                    v-if="!showAnamnese"
                  >
                    <b-icon-chevron-down class="mr-2" />Exibir
                  </b-btn>
                  <div
                    class="p-2"
                    v-if="showAnamnese"
                  >
                    <anamnese-preecher
                      v-for="(anamnese, index) in atendimentoObj.anamneses"
                      :anamnese="anamnese"
                      :key="index"
                      :editable="editable"
                    ></anamnese-preecher>
                  </div>
                </b-card-text>
              </b-card>
              <div id="anamnese"></div>

              <b-card>
                <b-card-title>Queixas</b-card-title>
                <b-card-text>
                  <b-textarea
                    rows="8"
                    v-model="atendimentoObj.queixas"
                    :readonly="!editable"
                  />
                </b-card-text>
              </b-card>
              <div id="queixas"></div>
              <b-card class="mt-4">
                <b-card-title class="">
                  <div class="d-flex justify-content-between">
                    <div>
                      CID`s
                      <small>( Classificação Internacional de Doenças)</small>
                    </div>
                  </div>
                </b-card-title>
                <b-card-text>
                  <c-i-d-finder @input="addCID"></c-i-d-finder>

                  <b-table
                    class="mt-2"
                    :fields="[
                      { key: 'id', label: 'Código' },
                      { key: 'descricao', label: 'Descrição' },
                      { key: 'dt', label: '' },
                    ]"
                    empty-text="Nenhum CID cadastrado"
                    empty-filtered-text="Nenhum CID encontrado"
                    :items="atendimentoObj.cids"
                  >
                    <template #cell(dt)="row">
                      <b-button
                        size="sm"
                        variant="danger"
                        @click="() => atendimentoObj.cids.splice(row.index, 1)"
                        ><i class="fa fa-trash"
                      /></b-button>
                    </template>
                  </b-table>
                </b-card-text>
              </b-card>
              <div id="cids"></div>

              <b-card class="mt-4">
                <b-card-title class="">
                  <div class="d-flex justify-content-between">
                    <div>Receitas</div>
                    <div>
                      <b-btn
                        variant="success"
                        v-if="editable"
                        >Adicionar</b-btn
                      >
                    </div>
                  </div>
                </b-card-title>
              </b-card>
              <div id="receitas"></div>

              <b-card class="mt-4">
                <b-card-title class="">
                  <div class="d-flex justify-content-between">
                    <div>Exames (solicitação)</div>
                    <div>
                      <b-btn
                        variant="success"
                        v-if="editable"
                        >Adicionar</b-btn
                      >
                    </div>
                  </div></b-card-title
                >
              </b-card>
              <div id="exames"></div>

              <b-card class="mt-4">
                <b-card-title class="">
                  <div class="d-flex justify-content-between">
                    <div>Atestados</div>
                    <div>
                      <b-btn
                        variant="success"
                        v-if="editable"
                        >Adicionar</b-btn
                      >
                    </div>
                  </div>
                </b-card-title>
              </b-card>
              <div id="atestados"></div>

              <b-card class="mt-4">
                <b-card-title class="">Anexos </b-card-title>
                <b-card-text> </b-card-text>
              </b-card>
              <div id="anexos"></div>

              <b-card class="mt-4">
                <b-card-title class="">Análise Final (médico) </b-card-title>
                <b-card-text>
                  <b-textarea
                    rows="8"
                    :readonly="!editable"
                    v-model="atendimentoObj.analise_final"
                  ></b-textarea>
                </b-card-text>
              </b-card>
              <div id="analise"></div>
            </b-tab>
            <b-tab>
              <template v-slot:title> <b-icon-alt /> Retorno </template>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <div @click="showAnamnese = true">
              <b-icon-file-medical class="ml-3" /> Anamnese
            </div>
          </template>
          <b-card class="my-4">
            <b-card-title class="d-flex justify-content-between">
              <div>Anamnese</div>
            </b-card-title>
            <b-card-text>
              <b-btn
                variant="info"
                class="m-auto"
                block
                @click="showAnamnese = true"
                v-if="!showAnamnese"
              >
                <b-icon-chevron-down class="mr-2" />Exibir
              </b-btn>
              <div
                class="p-2"
                v-if="showAnamnese"
              >
                <anamnese-preecher
                  v-for="(anamnese, index) in atendimentoObj.anamneses"
                  :anamnese="anamnese"
                  :key="index"
                  :editable="editable"
                ></anamnese-preecher>
              </div>
            </b-card-text>
          </b-card>
        </b-tab>
        <b-tab>
          <template v-slot:title> <b-icon-clipboard class="ml-3" /> Queixas </template>
          <b-card>
            <b-card-title>Queixas</b-card-title>
            <b-card-text>
              <b-textarea
                rows="8"
                v-model="atendimentoObj.queixas"
                :readonly="!editable"
              />
            </b-card-text>
          </b-card>
        </b-tab>
        <b-tab>
          <template v-slot:title> <b-icon-bookmark-heart-fill class="ml-3" /> CID`s </template>
          <b-card class="mt-4">
                <b-card-title class="">
                  <div class="d-flex justify-content-between">
                    <div>
                      CID`s
                      <small>( Classificação Internacional de Doenças)</small>
                    </div>
                  </div>
                </b-card-title>
                <b-card-text>
                  <c-i-d-finder @input="addCID"></c-i-d-finder>

                  <b-table
                    class="mt-2"
                    :fields="[
                      { key: 'id', label: 'Código' },
                      { key: 'descricao', label: 'Descrição' },
                      { key: 'dt', label: '' },
                    ]"
                    empty-text="Nenhum CID cadastrado"
                    empty-filtered-text="Nenhum CID encontrado"
                    :items="atendimentoObj.cids"
                  >
                    <template #cell(dt)="row">
                      <b-button
                        size="sm"
                        variant="danger"
                        @click="() => atendimentoObj.cids.splice(row.index, 1)"
                        ><i class="fa fa-trash"
                      /></b-button>
                    </template>
                  </b-table>
                </b-card-text>
              </b-card>
        </b-tab>
        
        <b-tab>
          <template v-slot:title> <b-icon-eyedropper class="ml-3" /> Receitas </template>
          <paciente-receita :paciente="paciente" :horario_id="horario.id"></paciente-receita>
        </b-tab>

        <b-tab>
          
          <template v-slot:title> <b-icon-file-earmark-medical  class="ml-3"  /> Exames </template>
          
        </b-tab>

        <b-tab>
          
          <template v-slot:title> <b-icon-paperclip  class="ml-3"  /> Anexos </template>
          
        </b-tab>
        <b-tab>
          <template v-slot:title> <b-icon-clock-history /> Histórico </template>
          asda
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EntidadesLib from "@/libs/EntidadesLib";
import FilesLib from "@/libs/FilesLib";
import AtendimentoLib from "../../../libs/AtendimentoLib";
import AnamnesePreecher from "../../../components/Anamnese/AnamnesePreecher.vue";
import CIDFinder from "../../../components/common/CIDFinder.vue";
import PacienteReceita from "../../../components/Entidades/Paciente/PacienteReceitas/PacienteReceita.vue";
export default {
  components: { AnamnesePreecher, CIDFinder, PacienteReceita },
  props: {
    paciente: Object,
    paciente_id: [Number, String],
    horario: Object,
    closeOnSave: { type: Boolean, default: false },
    prePaciente: {
      type: [Object, null],
      default: null,
    },
  },
  mounted() {
    this.$emit("loading", true);
    this.$forceUpdate();
    this.$nextTick();
    this.carregar();
    if (this.horario) {
      this.startCalculaTempo();
    }
  },
  data() {
    return {
      showAnamnese: false,
      EntidadeObj: Object.assign({}, this.$props.paciente),
      token: this.$store.state.auth.user.token,
      editando: false,
      loadingFoto: false,
      timer: null,
      tempo: "...",
      medico: this.$store.state.auth.user.perfil == "Entidade",
      loadingResinc: false,
      editable: false,
      atendimentoObj: {
        paciente_id: null,
        medico_id: null,
        horario_id: null,
        agenda_id: null,
        user_id: null,
        empresa_id: null,
        queixas: null,
        analise_final: null,
        retorno: null,
        status: 0,
        data_atendimento: null,
        inicio_atendimento: null,
        fim_atendimento: null,
        // created_at: null,
        // updated_at: null,
        receitas: [],
        exames: [],
        atestados: [],
        anexos: [],
        anamneses: [],
        cids: [],
      },
      tmrUpdate: null,
      loaded: false,
    };
  },
  watch: {
    horario: {
      handler: function (val) {
        if (val && val.at_inicio && !val.at_termino) {
          this.startCalculaTempo();
        }
      },
      deep: true,
    },
    atendimentoObj: {
      deep: true,
      handler() {
        if (this.loaded) {
          if (this.tmrUpdate) {
            clearTimeout(this.tmrUpdate);
          }
          this.tmrUpdate = setTimeout(() => {
            this.storeAtendimento();
          }, 1000);
        }
      },
    },
  },
  computed: {},
  methods: {
    addCID(cid) {
      console.log("addCID", cid);
      console.log("atendimento", this.atendimentoObj);
      if (!this.atendimentoObj.cids) {
        this.atendimentoObj.cids = [];
      }
      this.atendimentoObj.cids.push(cid);
    },
    scrollTo(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "end",
        });
        const modal = document.getElementById(".modal");
        if (modal) {
          modal.scrollBy({
            top: -100,
            behavior: "smooth",
          });
        }
      }
    },
    convertCamelCaseToTitleCase(str) {
      return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },
    async storeAtendimento() {
      this.atendimentoObj.paciente_id = this.horario.id_paciente;
      this.atendimentoObj.medico_id = this.horario.entidade_id;
      this.atendimentoObj.horario_id = this.horario.id;
      this.atendimentoObj.agenda_id = this.horario.id_agenda;
      this.atendimentoObj.empresa_id = this.horario.clinica_id;
      this.atendimentoObj.user_id = this.user.id;
      this.atendimentoObj.inicio_atendimento = this.horario.at_inicio;
      this.atendimentoObj.fim_atendimento = this.horario.at_termino;
      let result = await AtendimentoLib.storeAtendimento(this.atendimentoObj);
      this.$swal({
        title:
          result && result.success
            ? "Atendimento atualizado!"
            : "Erro ao atualizar atendimento",
        toast: true,
        timer: 1000,
        showConfirmButton: false,
        position: "top-end",
        icon: result && result.success ? "success" : "error",
      });
    },
    async carregar() {
      this.EntidadeObj = Object.assign({}, this.$props.paciente);
      if (this.horario && this.horario.at_inicio && !this.horario.at_termino) {
        this.timer = setInterval(this.calculaTempo, 1000);
      }
      const id = this.paciente_id || this.paciente?.id || this.EntidadeObj.id;
      console.log("id", id);
      if (id) {
        await EntidadesLib.get("paciente", "", id)
          .then((res) => {
            if (res.entidades.length > 0) {
              let entidade = res.entidades[0];
              console.log("entidade encontrada", entidade);
              if (entidade) {
                this.EntidadeObj = entidade;
              }
            }
          })
          .finally(() => {
            this.EntidadeObj.dt_nascimento = this.EntidadeObj?.dt_nascimento
              ? moment(this.EntidadeObj?.dt_nascimento).format("YYYY-MM-DD")
              : null;
          });

        //  console.log(this.EntidadeObj);
      }
      await this.carregarAtendimento();
      await this.storeAtendimento();
      await this.carregarAtendimento();
      this.loaded = true;
    },
    async carregarAtendimento() {
      let resultGet = await AtendimentoLib.getAtendimento({
        horario_id: this.horario.id,
      });
      console.log("resultGet", resultGet);
      if (resultGet && resultGet.success && resultGet.data.length > 0) {
        this.atendimentoObj = resultGet.data[0];
      }
    },
    startCalculaTempo() {
      setInterval(this.calculaTempo, 1000);
    },
    calculaTempo() {
      if (this.horario.at_inicio) {
        this.editable = true;

        var diffTime = moment.duration(
          moment(moment()).diff(moment(this.horario.at_inicio))
        );

        this.tempo =
          (diffTime.hours() > 0
            ? (diffTime.hours() < 10 ? "0" : "") + diffTime.hours() + ":"
            : "") +
          (diffTime.minutes() < 10 ? "0" : "") +
          diffTime.minutes() +
          ":" +
          (diffTime.seconds() < 10 ? "0" : "") +
          diffTime.seconds();
        // console.log(1);
      }
    },
    startAtendimento() {
      if (this.horario) {
        this.$emit("iniciarAtedimento", this.horario);
      }
    },
    stopAtendimento() {
      if (this.horario) {
        this.$emit("finalizarAtedimento", this.horario);
      }
    },
    formataUrl() {
      if (!this.EntidadeObj?.foto_entidade) return null;
      if (this.EntidadeObj?.foto_entidade?.indexOf("blob") >= 0) {
        return this.EntidadeObj.foto_entidade;
      }
      return FilesLib.downlaodFile(this.EntidadeObj?.foto_entidade, this.token);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</>
