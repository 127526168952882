<template>
  <div class="row" :class="customClass">
    <div
      :class="
        `pr-1 col-${12 -
          showBotaoSearch -
          (pacienteSel &&
          pacienteSel.id &&
          pacienteSel.id > 0 &&
          showBotaoEdit &&
          selecionado
            ? 1
            : 0) -
          showBotaoRemove}`
      "
    >
      <vue-simple-suggest
        v-model="pacienteSel"
        :list="pacienteLista"
        :styles="autoCompleteStyle"
        :disabled="disabled"
        :filter-by-query="false"
        autocomplete="off"
        @select="
          $emit('select', $event);
          onSelect($event);
        "
        :min-length="minLength"
        :debounce="500"
        ref="select"
        display-attribute="razao_social"
        mode="select"
        :controls="{
          selectionUp: [38, 33],
          selectionDown: [40, 34],
          select: [13, 36],
          showList: [40],
          hideList: [27, 35, 13],
        }"
        @input="onInput"
        @request-start="loading = true"
        @request-done="loading = false"
      >
        <input
          class="form-control optional-custom-input"
          v-model="pacienteSel.razao_social"
          autocomplete="off"
          :id="`txtField${Math.random()}`"
          :name="`txtField${Math.random()}`"
        />
        <div slot="suggestion-item" slot-scope="{ suggestion }">
          <div>
            <div class="text-left">
              {{ suggestion.razao_social }}
            </div>
            <div v-if="!suggestion.cpf" class="text-center bg-light">
              <h5 class="text-danger">Cadastro incompleto</h5>
            </div>
            <!-- {{suggestion}} -->
            <small v-if="suggestion.idade && !is['medico']"
              >({{ suggestion.idade }} anos)</small
            >
            <br
              v-if="
                suggestion.cpf ||
                  (Array.isArray(suggestion.especialidadesOBJ) &&
                    suggestion.especialidadesOBJ.length > 0)
              "
            />

            <small>
              <div class="d-flex justify-content-between">
                <small v-if="suggestion.cpf && !is['medico']"
                  >Doc.: {{ suggestion.cpf }}</small
                >
                <small
                  v-if="
                    is['medico'] &&
                      Array.isArray(suggestion.especialidadesOBJ) &&
                      suggestion.especialidadesOBJ.length > 0
                  "
                >
                  {{
                    suggestion.especialidadesOBJ
                      .map((e) => e.especialidade)
                      .join(", ")
                  }}
                </small>
                <small
                  v-if="
                    suggestion.telefones &&
                      suggestion.telefones.length > 0 &&
                      !is['medico']
                  "
                  >Fone: {{ suggestion.telefones[0].telefone }}</small
                >
                <small v-if="suggestion.crm && is['medico']">
                  <b>CRM: </b> {{ suggestion.crm }}
                </small>
              </div>
              <div
                v-if="
                  Array.isArray(suggestion.dependenteDe) &&
                    suggestion.dependenteDe.length > 0
                "
              >
                <small
                  class=""
                  v-if="
                    Array.isArray(suggestion.dependenteDe) &&
                      suggestion.dependenteDe.length > 0
                  "
                >
                  Dependente de:
                  <b>{{
                    suggestion.dependenteDe.map((d) => d.titular).join(", ")
                  }}</b></small
                >
              </div>
            </small>
          </div>
        </div>
      </vue-simple-suggest>
    </div>
    <div
      :class="
        `col-${showBotaoSearch +
          (pacienteSel &&
            pacienteSel.id &&
            pacienteSel.id > 0 &&
            showBotaoEdit &&
            selecionado) +
          showBotaoRemove} pl-0`
      "
      style=""
    >
      <span
        v-if="
          pacienteSel &&
            pacienteSel.id &&
            pacienteSel.id > 0 &&
            showBotaoEdit &&
            selecionado
        "
      >
        <b-button
          :disabled="disabled || loading"
          variant="primary"
          size="md"
          @click="$emit('edit', pacienteSel)"
        >
          <i v-if="!loading" class="fa fa-edit" />
          <b-spinner small v-if="loading"></b-spinner>
        </b-button>
      </span>
      <span v-if="showBotaoAdd">
        <b-button
          :disabled="disabled || loading"
          variant="primary"
          size="md"
          @click="$emit('new')"
        >
          <b-icon-person-plus v-if="!loading" />
          <b-spinner small v-if="loading"></b-spinner>
        </b-button>
      </span>
      <span v-if="showBotaoSearch">
        <b-button
          :disabled="disabled || loading"
          variant="primary"
          size="md"
          @click="openSearch"
          v-if="!selecionado"
        >
          <i class="fas fa-search" v-if="!loading"></i>
          <b-spinner small v-if="loading"></b-spinner>
        </b-button>

        <b-button
          :disabled="disabled || loading"
          variant="primary"
          size="md"
          @click="deselect"
          v-if="selecionado && showBotaoRemove"
        >
          <i class="fas fa-times" v-if="!loading"></i>
          <b-spinner small v-if="loading"></b-spinner>
        </b-button>
      </span>
    </div>

    <b-modal
      :hide-backdrop="hideBackdrop"
      :size="searchModalSize"
      hide-footer
      :id="`modalSearch${tipo}`"
    >
      <template #modal-title>
        <i class="fas fa-search"></i> Buscar {{ searchName }}
      </template>
      <search-entidades
        :tabs="tipo"
        @input="onSelect($event)"
        @close="closeSearch"
        :withoutConvenio="withoutConvenio"
      ></search-entidades>
    </b-modal>
  </div>
</template>

<script>
import EntidadeLib from "../../libs/EntidadesLib";
import { tiposDeEntidade } from "../../helpers/tiposDeEntidade";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import SearchEntidades from "./SearchEntidades.vue";

export default {
  components: {
    VueSimpleSuggest,
    SearchEntidades,
  },
  props: {
    showBotaoAdd: {
      type: Boolean,
      default: true,
    },
    showBotaoEdit: {
      type: Boolean,
      default: true,
    },
    showBotaoRemove: {
      type: Boolean,
      default: true,
    },
    showBotaoSearch: {
      type: Boolean,
      default: true,
    },
    showFidelizacoes: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
    setNullOnEmpty: {
      type: Boolean,
      default: false,
    },
    minLength: {
      type: [String, Number],
      default: 3,
    },
    withoutConvenio: {
      type: Boolean,
      default: false,
    },
    selecionado: { type: [String, Number, Object], default: null },
    tipo: {
      type: [Array],
      default: () => tiposDeEntidade,
    },
    searchModalSize: {
      type: [String],
      default: "lg",
    },
    customClass: {
      type: [String],
    },
    value: { type: Object, default: null },
    text: String,
  },
  async created() {
    this.$forceUpdate();
    await this.$nextTick();
    if (this.tipo.indexOf("*") !== -1) {
      this.tipo = tiposDeEntidade;
    }

    this.carregar();
    if (this.text) {
      this.$refs.select.text = this.text;
      if (typeof this.$refs.select.research === "function") {
        this.$refs.select.research();
      }
    }
  },
  data() {
    return {
      pacienteSel: {
        razao_social: "",
      },

      loading: false,

      autoCompleteStyle: {
        defaultInput: "form-control",
        suggestions:
          "position-absolute list-group z-1000 auto-complete-suggestion-correction",
        suggetItem: "suggestItem",
        lastFind: "",
      },
    };
  },
  watch: {
    // pacienteSel: function(newV, oldv) {
    //   //console.log('pacienteSel changed',newV, oldv);
    //   this.$emit("paciente", this.pacienteSel);
    // },
    selecionado: function() {
      // //console.log('select',this.selecionado);
      this.carregar();
    },
    value: {
      deep: true,
      handler() {
        ////console.log("value", this.value);
        if (this.value && this.value.razao_social) {
          this.pacienteSel = this.value;
        } else {
          this.parcienteSel = {
            razao_social: "",
          };
        }
      },
    },
    text(newV) {
      if (newV) {
        this.$refs.select.text = newV;
      }
    },
    disabled: function() {
      this.$forceUpdate();
    },
  },
  computed: {
    searchName() {
      return this.tipo && Array.isArray(this.tipo) && this.tipo.length > 1
        ? "Entidades"
        : this.tipo[0]
        ? this.tipo[0].charAt(0).toUpperCase() + this.tipo[0].slice(1)
        : "";
    },
    is() {
      let obj = {};
      tiposDeEntidade.forEach((t) => {
        obj = Object.assign(obj, { [t]: this.tipo.includes(t) });
      });
      return obj;
    },
  },
  methods: {
    clearPaciente() {
      this.pacienteSel.razao_social = "";
      this.pacienteSel.id = null;
    },
    forceSearch() {
      //console.log("forcingSearch", this.pacienteSel);
      setTimeout(async () => {
        //this.$refs.select.showList();
        await this.$refs.select.research();
        //console.log('executing ')
        this.$refs.select.showList();

        setTimeout(() => {
          this.$refs.select.getSuggestions(this.pacienteSel.razao_social);
        }, 300);
      }, 200);
    },
    handlerPaciente(paciente) {
      let cadastroIncompleto = false;
      if (paciente) {
        cadastroIncompleto = !paciente?.cpf || !paciente?.id;
      }
      this.$emit("paciente", paciente, cadastroIncompleto);
    },
    async carregar() {
      console.log("------>", this.selecionado, this.text);
      //this.deselect();
      if (this.text) {
        this.pacienteSel = {
          razao_social: this.text,
        };
      }
      try {
        this.$nextTick();

        if (this.selecionado) {
          // //console.log("SELECIONADO", this.selecionado, !!this.selecionado);
          let idd = this.selecionado;
          if (typeof idd == "object" && idd.id) {
            idd = idd.id;
          }
          let res = await EntidadeLib.buscaPorCampo({ id: idd, cartao: idd });
          // //console.log("encontrou", res);
          if (res && res.length > 0) {
            this.pacienteSel = res[0];
            //this.handlerPaciente(this.pacienteSel);
            this.$emit("paciente", this.pacienteSel);
          } else {
            this.pacienteSel = {
              razao_social: this.text,
            };
          }
          // //console.log('buscou selecionado',this.selecionado, this.pacienteSel)
        } else {
          this.pacienteSel = {
            razao_social: "",
          };
          if (this.text) {
            this.pacienteSel = {
              razao_social: this.text,
            };
          }
        }
        this.$emit("isCreated");
      } catch (err) {
        //console.log("erro ", err);
        if (this.text) {
          this.pacienteSel = {
            razao_social: this.text,
          };
        }
      }
    },
    onSelect(entidade) {
      //  //console.log("onSelect", entidade);
      if (entidade?.id) {
        this.pacienteSel = Object.assign({}, entidade);
        //this.$emit("paciente", entidade);
        this.handlerPaciente(entidade);
        // this.$emit("input", entidade);
      } else {
        this.pacienteSel = {
          razao_social: "",
        };
      }
    },
    onInput(value) {
      this.$emit("input", value);
    },
    deselect() {
      ////console.log("deselect");
      this.pacienteSel = {
        razao_social: "",
      };
      this.selecionado = null;

      this.handlerPaciente(null);
    },
    openSearch() {
      this.$bvModal.show(`modalSearch${this.tipo}`);
    },
    closeSearch() {
      this.$bvModal.hide(`modalSearch${this.tipo}`);
    },
    pacienteLista(busca) {
      // //console.log("BUSCA: ", busca);
      if (this.setNullOnEmpty) {
        if (busca.trim().length == 0 && !this.selecionado) {
          // this.pacienteSel = null;
          //  this.$emit("paciente", null);
          return;
        }
      }
      if (busca != this.lastFind && busca.length >= 3) {
        // //console.log(1);
        this.lastFind = busca;
        let tipo = this.tipo;
        return new Promise(function(resolve, reject) {
          ////console.log("start busca", busca);
          let lista = EntidadeLib.buscaPorNome(busca, tipo, 10);
          lista
            .then((res) => {
              // //console.log(123, res);
              console.log("res", res);
              resolve(res);
            })
            .catch(() => {
              reject();
            });
        });
      }
    },
    // selectSugg(e) {
    //   if (e) {
    //     this.horarioEdit.id_paciente = null;
    //     this.horarioEdit.pacienteSel = null;
    //     this.fillSelected(e);
    //     this.lastFind = e.razao_social;
    //     this.horarioEdit.paciente = e.razao_social;
    //     this.horarioEdit.id_paciente = e.id;
    //     this.horarioEdit.pacienteSel = e;
    //     this.$refs.txtIdade.focus();
    //   }
    // },
    // fillSelected(e) {
    //   this.horarioEdit.telefone = "";
    //   this.horarioEdit.idade = "";
    //   this.horarioEdit.cpf = "";

    //   if (e) {
    //     if (e.id) {
    //       this.horarioEdit.id_paciente = e.id;
    //     }
    //     if (e.telefones && e.telefones.length > 0) {
    //       this.horarioEdit.telefone = e.telefones[0].telefone;
    //     }
    //     if (e.idade) {
    //       this.horarioEdit.idade = e.idade;
    //     }
    //     if (e.cpf) {
    //       this.horarioEdit.cpf = e.cpf;
    //     }
    //     // this.$refs.txtIdade.focus();
    //   }
    // },
    // async selectSuggFind() {
    //   if (this.horarioEdit.paciente && this.horarioEdit.paciente != "") {
    //     if (
    //       this.horarioEdit.pacienteSel &&
    //       this.horarioEdit.pacienteSel.razao_social != this.horarioEdit.paciente
    //     ) {
    //       this.horarioEdit.id_paciente = null;
    //       this.horarioEdit.pacienteSel = null;
    //       let find = (
    //         await this.pacienteLista(this.horarioEdit.paciente)
    //       ).filter(
    //         (x) =>
    //           x.razao_social &&
    //           x.razao_social.toLowerCase() ==
    //             this.horarioEdit.paciente.toLowerCase()
    //       );
    //       if (find.length >= 0) {
    //         this.horarioEdit.id_paciente = find[0].id;
    //         this.horarioEdit.pacienteSel = find[0];
    //         this.horarioEdit.paciente = find[0].razao_social;
    //         this.fillSelected(find[0]);
    //         // this.$refs.txtIdade.focus();
    //       }
    //     } else {
    //       this.horarioEdit.id_paciente = this.horarioEdit.pacienteSel.id;
    //       this.fillSelected(this.horarioEdit.pacienteSel);
    //       // this.$refs.txtIdade.focus();
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped></style>
