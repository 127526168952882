import axios from "../utils/axios";

const get = async ({ paciente_id = "", id = "" }) => {
  let result;
  await axios
    .get("pacientes/anamneses", { params: { id, paciente_id } })
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao buscar anamneses!");
      }
    });

  return result;
};

const store = async (data, isAuth=true) => {
  console.log('isAuth', isAuth)
  let result = await axios
    .post(isAuth?"pacientes/anamneses":"pacienteAnamnese/store", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao inserir anamnese!");
      }
    });
  return result;
};

const del = async (id) => {
  let result = await axios
    .delete(`pacientes/anamneses/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data && result.data.error) {
        throw err.response.data.error.message;
      } else {
        throw new Error("Erro ao excluir anamnese!");
      }
    });
  return result;
};

const getDef = async (data) => {
  let result;
  await axios
    .post(`pacienteAnamnese`, data)
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao buscar anamneses!");
      }
    });

  return result;
};

export default {
  get,
  store,
  del,
  getDef
};
