<template>
  <div>
    <div class="pacienteFicha" v-if="EntidadeObj">
      <div class="pacienteInfo">
        <div class="foto">
          <div class="text-center mt-3" v-if="loadingFoto">
            <b-spinner></b-spinner>
          </div>
          <img
            :draggable="false"
            class="img-fluid"
            :src="formataUrl()"
            @click="selectFile()"
            style="cursor: pointer"
            v-if="!loadingFoto"
          />
          <input
            type="file"
            ref="fl"
            style="visibility: hidden; width: 0; height: 0px; margin: 0"
            @change="uploadFile()"
          />
        </div>
        <div style="flex-grow: 1">
          <div class="nome">
            {{ EntidadeObj.razao_social }}
          </div>
          <div class="infos">
            <div class="info" v-if="EntidadeObj.dt_nascimento">
              <label>Código</label>
              {{ EntidadeObj.id }}
            </div>
            <div class="info" v-if="EntidadeObj.dt_nascimento">
              <label>Nascimento</label>
              {{ EntidadeObj.dt_nascimento | moment("DD/MM/YYYY") }}
              ({{ EntidadeObj.idade }})
            </div>
            <div class="info">
              <label>CPF</label>
              {{ EntidadeObj.cpf }}
            </div>
            <div
              class="info"
              v-if="EntidadeObj.telefones && EntidadeObj.telefones.length > 0"
            >
              <label>Telefone</label>
              {{ EntidadeObj.telefones[0].telefone }}
            </div>

            <div class="info pl-2 ml-2" v-if="horario">
              <b-button
                v-if="!horario.at_inicio"
                variant="success"
                @click="startAtendimento"
                ><i class="fa fa-play" /> Iniciar Atendimento</b-button
              >
              <div v-if="horario.at_inicio && !horario.at_termino">
                <label><i class="fa fa-clock" /> Atendimento Iniciado</label>
                {{ horario.at_inicio | moment("DD/MM/YYYY HH:mm:ss") }} ({{
                  tempo
                }})
              </div>
            </div>
            <div
              class="info pl-2 ml-2"
              v-if="horario && horario.at_inicio && !horario.at_termino"
            >
              <b-button variant="danger" @click="stopAtendimento"
                ><i class="fa fa-play" /> Finalizar Atendimento</b-button
              >
            </div>
          </div>
        </div>
 
        <div class="fichaClose">
          <a href="#" class="btClose" @click="close">
            <i class="fa fa-times" />
          </a>
        </div>
      </div>
      <div class="fichaConteudo">
        <b-tabs
          pills
          vertical
          nav-wrapper-class="tabsPaciente"
          active-nav-item-class="tabLinkItemAtivoNoRadius"
          active-tab-class="tabLinkItemAtivo"
          content-class="tabContentPaciente"
          @activate-tab="change"
          ref="PacienteTabs"
        >
          <b-tab title="cadastro">
            <template #title>
              <img
                src="@/assets/img/icones/menu/icons8_admin_settings_male_40px.png"
                class="fichaIcon"
                style="margin-left: -3px"
              />
              Cadastro
            </template>
            <div class="pr-2">
              <entidades-form
                :entidadeObj="EntidadeObj"
                :entidadeId="EntidadeObj.id"
                :tipo="'paciente'"
                :saveButton="true"
                @carregar="carregarExt"
                @onSave="onSave"
                @close="$emit('close')"
                :autoSave="false"
                ref="form"
              />
            </div>
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <i class="fa fa-wallet mr-1"></i>
              Créditos
            </template>
            <paciente-creditos :entidadeId="EntidadeObj.id"></paciente-creditos>
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/icons8_user_menu_male_40px.png"
                class="fichaIcon"
              />
              Histórico
            </template>
            <paciente-historico ref="historico" :paciente_id="EntidadeObj.id" />
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/icons8_treatment_40px.png"
                class="fichaIcon"
              />
              Anamnese
            </template>
            <paciente-anamnese :horario="horario" :paciente="EntidadeObj" />
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/stethoscope_40px.png"
                class="fichaIcon"
              />
              Consultas
            </template>

            <paciente-consultas :paciente="EntidadeObj"></paciente-consultas>
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/icons8_heart_with_pulse_40px.png"
                class="fichaIcon"
              />
              Exames
            </template>
            <paciente-exames
              :horario="horario"
              :paciente="EntidadeObj"
            ></paciente-exames>
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/icons8_autograph_40px.png"
                class="fichaIcon"
              />
              Receitas
            </template>
            <paciente-receitas
              ref="receitas"
              @salvou="loadAll"
              :horario_id="horario ? horario.id || null : null"
              :paciente="EntidadeObj"
            />
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/icons8_user_clock_40px.png"
                class="fichaIcon"
              />
              Atestados
            </template>
            <paciente-atestado
              :horario="horario"
              :paciente="EntidadeObj"
              @salvou="onSaveAtestado"
            />
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/loyalty_40px.png"
                class="fichaIcon"
              />
              Fidelização
            </template>

            <paciente-fidelidades :paciente="EntidadeObj" />
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/attach_40px.png"
                class="fichaIcon"
              />
              Anexos
            </template>

            <div class="p-3">
              <Anexos
                :salvarDireto="true"
                @salvo="salvar"
                @excluir="salvar"
                tipo="Entidades"
                ficha
                :anexos="EntidadeObj.anexos"
                ref="anexoComponent"
              />
              <EmptyState
                v-if="EntidadeObj.anexos && EntidadeObj.anexos.length == 0"
              >
                <h3>Nenhuma anexo encontrado!</h3>
              </EmptyState>
            </div>
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/icons8-calendário-40.png"
                class="fichaIcon"
              />
              Agendas / Horários
            </template>
            <!-- {{EntidadeObj.anexos}} -->
            <div class="px-4">
              <paciente-agendas-horarios
                class=""
                :paciente="EntidadeObj"
                :id_paciente="EntidadeObj.id"
              ></paciente-agendas-horarios>
            </div>
          </b-tab>
          <b-tab v-if="EntidadeObj && EntidadeObj.id && EntidadeObj.id > 0">
            <template #title>
              <img
                src="@/assets/img/icones/menu/data_transfer_40px.png"
                class="fichaIcon"
              />
              Encaminhamento Médico
            </template>
            <!-- {{EntidadeObj.anexos}} -->
            <div class="px-4">
              <paciente-encaminhamentos
                :horario="horario"
                :paciente="EntidadeObj"
              ></paciente-encaminhamentos>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import FilesLib from "../../../libs/FilesLib.js";

import axios from "@/utils/axios.js";
import EntidadesLib from "../../../libs/EntidadesLib.js";

import moment from "moment";

export default {
  name: "PacienteFicha",
  components: {
    EntidadesForm: () => import("../EntidadesFormNew.vue"),
    PacienteAtestado: () => import("./PacienteAtestado.vue"),
    PacienteHistorico: () => import("./PacienteHistorico.vue"),
    PacienteAnamnese: () => import("./PacienteAnamnese/PacienteAnamnese.vue"),
    PacienteExames: () => import("./PacienteExames/PacienteExames.vue"),
    PacienteReceitas: () => import("./PacienteReceitas/PacienteReceita.vue"),
    Anexos: () => import("../../Anexos/Anexos.vue"),
    PacienteAgendasHorarios: () =>
      import("./PacienteAgendasHorarios/PacienteAgendasHorarios.vue"),
    PacienteFidelidades: () =>
      import("./PacienteFidelidades/PacienteFidelidades.vue"),
    EmptyState: () => import("@/components/common/EmptyState.vue"),
    PacienteConsultas: () =>
      import("./PacienteConsultas/PacienteConsultas.vue"),
    PacienteEncaminhamentos: () =>
      import("./PacienteEncaminhamentos/PacienteEncaminhamentos.vue"),
    PacienteCreditos: ()=> import("./PacienteCreditos.vue"),
  },
  props: {
    paciente: Object,
    paciente_id: [Number, String],
    horario: Object,
    closeOnSave: { type: Boolean, default: false },
    prePaciente: {
      type: [Object, null],
      default: null,
    },
  },

  watch: {
    paciente: function (newV, oldV) {
      if (
        newV &&
        oldV &&
        typeof newV === "object" &&
        typeof oldV === "object"
      ) {
        if (newV?.id !== oldV?.id) this.carregar();
      }
      this.EntidadeObj = this.$props.paciente;
      console.log("bugou aqui", this.EntidadeObj);
      this.$forceUpdate();
    },
  },
  beforeCreate() {
    this.$emit("loading", false);
  },

  created() {
    this.$emit("loading", true);
    this.$forceUpdate();
    this.$nextTick();
    this.carregar();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  data() {
    return {
      EntidadeObj: Object.assign({}, this.$props.paciente),
      token: this.$store.state.auth.user.token,
      editando: false,
      loadingFoto: false,
      timer: null,
      tempo: "...",
      medico: this.$store.state.auth.user.perfil == "Entidade",
      loadingResinc: false,
    };
  },
  methods: {
    async carregarExt(id) {
      this.paciente_id = id;
      this.carregar();
    },
    async resincCLI() {
      this.loadingResinc = true;
      try {
        let res = await EntidadesLib.resinc(this.EntidadeObj.id);
        if (res && res.success) {
          this.$swal("Sucesso", "Re-sincronização concluída!", "success");
          this.carregar();
          if (this.closeOnSave) this.$emit("close", this.EntidadeObj);
        }
      } catch (err) {
        this.$swal("Erro", "Houve um erro ao re-sinc!", "error");
        console.log("Erro ", err);
      }
      this.loadingResinc = false;
      // axios.get("http://localhost:3333/importar?fidelizacoes=-1&id_cliente="+this.EntidadeObj.id);
      // axios.get("https://api.saudesocial.net/importar?fidelizacoes=-1&id_cliente="+this.EntidadeObj.id);
      // axios.get("http://localhost:3333/importar?clientes="+this.EntidadeObj.id);
      //this.$swal("Solicitação Realizada","Aguarde de 30 a 40 segundos para buscar os dados do cadastro novamente!", "success");
    },
    async carregar() {
      this.EntidadeObj = Object.assign({}, this.$props.paciente);
      if (this.horario && this.horario.at_inicio && !this.horario.at_termino) {
        this.timer = setInterval(this.calculaTempo, 1000);
      }
      const id = this.paciente_id || this.paciente?.id || this.EntidadeObj.id;
      console.log("id", id);
      if (id) {
        await EntidadesLib.get("paciente", "", id)
          .then((res) => {
            if (res.entidades.length > 0) {
              let entidade = res.entidades[0];
              console.log("entidade encontrada", entidade);
              if (entidade) {
                this.EntidadeObj = entidade;
              }
            }
          })
          .finally(() => {
            this.EntidadeObj.dt_nascimento = this.EntidadeObj?.dt_nascimento
              ? moment(this.EntidadeObj?.dt_nascimento).format("YYYY-MM-DD")
              : null;
          });

        //  console.log(this.EntidadeObj);
      } else if (
        this.prePaciente &&
        !this.EntidadeObj?.id &&
        !this.EntidadeObj_id
      ) {
        this.$swal({
          title: "Carregar dados do horário?",
          html: `Há alguns dados do paciente cadastrados no horário, deseja puxa-los? <hr/> <b>Nome: </b>${this.prePaciente.razao_social}<br/> <b>CPF: </b>${this.prePaciente.cpf}`,
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        }).then((re) => {
          if (re.isConfirmed) {
            this.EntidadeObj = Object.assign(
              this.EntidadeObj,
              this.prePaciente
            );
          }
        });
      } else {
        let preObj = {
          id: "",
          razao_social: "",
          fantasia: "",
          foto_entidade: "",
          crm: "",
          ie: "",
          cpf: "",
          email: "",
          enderecos: [],
          telefones: [],
          tipo: "PF",
          status: 1,
          // tipo_pessoa: "PF",
          configuracoes: {
            especialidades: [],
            contasBancarias: [],
            convenio: {
              limiteQtdCompras: null,
              limiteValorCompras: null,
              diaFechamento: null,
            },
          },

          convenio: 0,
          fornecedor: 0,
          laboratorio: 0,
          medico: 0,
          paciente: 1,
        };
        this.paciente = Object.assign(
          this.paciente ? this.paciente : {},
          preObj
        );
      }
    },
    async onSave(entidade) {
      //console.log("on save chaamdo", entidade);
      if (entidade.id) {
        this.EntidadeObj.id = entidade.id;
        // console.log("chamandoOnSave FICHA", entidade);
        //this.$emit("carregar");
        if (this.closeOnSave) this.$emit("close", entidade);
      }
    },
    calculaTempo() {
      if (this.horario.at_inicio) {
        var diffTime = moment.duration(
          moment(moment()).diff(moment(this.horario.at_inicio))
        );

        this.tempo =
          (diffTime.hours() > 0
            ? (diffTime.hours() < 10 ? "0" : "") + diffTime.hours() + ":"
            : "") +
          (diffTime.minutes() < 10 ? "0" : "") +
          diffTime.minutes() +
          ":" +
          (diffTime.seconds() < 10 ? "0" : "") +
          diffTime.seconds();
        // console.log(1);
      }
    },
    startAtendimento() {
      // if (this.horario) {
      //   this.$swal({
      //     title: "Tem certeza?",
      //     text: "Deseja iniciar o atendimento agora?",
      //     icon: "question",
      //     showCancelButton: true,
      //     cancelButtonText: "Não",
      //     confirmButtonText: "Sim",
      //   }).then((re) => {
      //     if (re.isConfirmed) {
      //this.horario.at_inicio = moment().format("YYYYY-MM-DD HH:mm:ss");
      // console.log(1, this.horario);
      this.$emit("iniciarAtedimento", this.horario);
      //     }
      //   });
      // }
    },
    stopAtendimento() {
      // if (this.horario) {
      //   this.$swal({
      //     title: "Tem certeza?",
      //     text: "Deseja finalizar o atendimento agora?",
      //     icon: "question",
      //     showCancelButton: true,
      //     cancelButtonText: "Não",
      //     confirmButtonText: "Sim",
      //   }).then((re) => {
      // if (re.isConfirmed) {
      //this.horario.at_inicio = moment().format("YYYYY-MM-DD HH:mm:ss");
      // console.log(1, this.horario);
      this.$emit("finalizarAtedimento", this.horario);
      // }
      //});
      //}
    },
    loadAll() {
      // console.log('refs --> ',this.$refs);
      // for (const ref of Object.keys(this.$refs)) {
      //   if (ref) {
      //     console.log('ref', ref, this.$refs[ref]);
      //     for (const fn of Object.keys(this.$refs[ref])) {
      //       if(typeof fn != 'function')return
      //       if (fn.indexOf('carreg') ) {
      //         this.$refs[ref][fn].apply()
      //       }
      //     }
      //   }
      // }
    },
    async salvar() {
      // console.log('chamou salvar no paciente ficha');
      await this.$nextTick();
      this.$forceUpdate();
      await EntidadesLib.store(this.EntidadeObj);
      await this.$refs.form.carregaEntidade();
    },
    change(e) {
      if (this.$props.paciente) {
        this.EntidadeObj = Object.assing({}, this.$props.paciente);
        this.$forceUpdate();
      }
      // console.log(e);
      e;
    },
    async selectFile() {
      // console.log(this.EntidadeObj);
      if (this.EntidadeObj.id == "") {
        this.showToast("error", "Por favor, cadastre o paciente antes.", 2800);
        return;
      }
      this.$refs.fl.value = "";

      this.$refs.fl.click();
    },
    formataUrl() {
      if (!this.EntidadeObj?.foto_entidade) return null;
      if (this.EntidadeObj?.foto_entidade?.indexOf("blob") >= 0) {
        return this.EntidadeObj.foto_entidade;
      }
      return FilesLib.downlaodFile(this.EntidadeObj?.foto_entidade, this.token);
    },
    async uploadFile() {
      await this.$nextTick();

      //  console.log(this.$refs.fl.files[0].name);
      if (this.$refs.fl.files.length > 0) {
        this.fileName = this.$refs.fl.files[0].name;
        var form = new FormData();

        form.append("file", this.$refs.fl.files[0]);

        this.loadingFoto = true;
        await this.$nextTick();
        await axios
          .post("/uploadFile", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (data) => {
            if (data && data.data && data.data.success) {
              this.loadingFoto = false;
              this.uploaded = true;

              this.EntidadeObj.foto_entidade = data.data.file;
              const result = await EntidadesLib.store(this.EntidadeObj);
              if (result.success) {
                this.showToast("success", "Foto atualizada com sucesso!");
              } else if (result.msg && result.msg.length > 0) {
                this.showToast(
                  "error",
                  `Erro ao atualizar a foto, há alguns dados não prenchidos corretamente!\n\n${result.msg}`,
                  4600
                );
              } else {
                this.showToast(
                  "error",
                  "Erro ao atualizar foto, por favor, tente novamente mais tarde!",
                  2600
                );
              }
            }
          });
      }
      await this.$refs.form.carregaEntidade();
    },
    onSaveAtestado() {
      //
    },
    close() {
      if (this.$refs.form.$data.hasChange) {
        this.$swal({
          title: "Salvar alterações?",
          text: "Você realizou alterações no cadastro. Deseja salvar antes de sair?",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Salvar Alterações",
          cancelButtonText: "Descartar",
        }).then(async (x) => {
          if (x.isConfirmed) {
            await this.$refs.form.salvar();
          }
          this.$emit("close");
        });
      } else {
        this.$emit("close");
      }
    },
  },
  // computed: {
  //   tabIndex() {
  //     return !this.EntidadeObj.id) ? 5 : 0;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
