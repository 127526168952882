<template>
  <div class="d-flex">
    <vue-simple-suggest
      v-model="cidSel"
      :list="cidLista"
      :disabled="disabled"
      :filter-by-query="false"
      autocomplete="off"
      @select="
        $emit('select', $event);
        onInput($event);
      "
      :min-length="minLength"
      :debounce="500"
      ref="select"
      display-attribute="descricao"
      mode="select"
      :controls="{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        showList: [40],
        hideList: [27, 35, 13],
      }"
      @input="onInput2"
      @request-start="loading = true"
      @request-done="loading = false"
      placeholder="digite para buscar uma doença ou CID"
      style="flex: 1"
    >
      <div
        slot="suggestion-item"
        slot-scope="{ suggestion }"
      >
        <div>
          <div class="text-left">
            {{ suggestion.id }} - {{ suggestion.descricao }}
          </div>

          <small>
            <div class="d-flex justify-content-between">
              <small v-if="suggestion.id">Código.: {{ suggestion.id }} </small>
            </div>
          </small>
        </div>
      </div>
    </vue-simple-suggest>
    <b-btn
      variant="info"
      @click="openFinder"
    >
      <b-icon-book-half />
    </b-btn>
    <b-modal
      id="modal-find-cid"
      hide-footer
      size="lg"
      title="Buscar CID"
    >
      <c-i-d-catalog
        :onModal="true"
        :selectable="true"
        @select="onInput"
      ></c-i-d-catalog>
    </b-modal>
  </div>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import CIDLib from "../../libs/CIDLib";
import CIDCatalog from "./CIDCatalog.vue";
export default {
  components: {
    VueSimpleSuggest,
    CIDCatalog,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    setNullOnEmpty: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      cidSel: "",
      minLength: 1,
      loading: false,
      lastFind: "",
    };
  },
  watch: {
    // cidSel(val) {
    //   this.$emit("select", val);
    //   this.cidSel = null;
    // },
  },
  computed: {},
  methods: {
    openFinder() {
      this.$bvModal.show("modal-find-cid");
    },
    onInput2(t) {
      //   console.log("onInput2", t);
      t;
    },
    async onInput(val) {
      //   console.log("emit", val);
      if (val && val.id && val.id.length > 0) {
        this.$emit("input", val);
        await this.$nextTick();
        await this.$forceUpdate();
        this.$refs.select.clearSuggestions();
        this.$refs.select.setText("");
        await this.$nextTick();
        await this.$forceUpdate();
        this.$bvModal.hide("modal-find-cid");
      }
    },
    cidLista(busca) {
      // //console.log("BUSCA: ", busca);
      if (this.setNullOnEmpty) {
        if (busca.trim().length == 0 && !this.selecionado) {
          return;
        }
      }
      if (busca != this.lastFind && busca.length >= 3) {
        // //console.log(1);
        this.lastFind = busca;

        return new Promise(function (resolve, reject) {
          let lista = CIDLib.getCIDByFilter({
            global: busca,
          });
          lista
            .then((res) => {
              resolve(res);
            })
            .catch(() => {
              reject();
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
