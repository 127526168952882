<template>
  <div :style="nivel ? `padding-left: ${(nivel + 1) * 15}px;` : ``">
    <div :style="nivel ? `font-size: ${20 - nivel * 5}px` : `font-size: 20px`">
      <div class="row">
        <div class="col-6">
          {{
            !grupo.grupoValor || grupo.grupoValor == "null"
              ? "Sem " + grupo.grupoNome
              : grupo.grupoValor
          }}
        </div>
        <div class="col-3 text-center">
          {{ grupo.qtd }}
        </div>
        <div class="col-3 text-center">
          {{ grupo.vl_total | currency }}
        </div>
      </div>
    </div>
    <hr />
    <div v-if="!grupo.isLastChild && grupo.isLastChild === false">
      <venda-grupos
        :grupo="gp2"
        v-for="(gp2, idx2) in grupo.children"
        :key="idx2"
        :nivel="nivel + 1"
        :filtros="filtros"
      ></venda-grupos>
    </div>
    <div
      class="pl-3"
      v-if="filtros && filtros.exibeDetalhe && filtros.exibeDetalhe == true"
    >
      <venda-detalhes
        v-if="grupo.isLastChild"
        :detalhes="grupo.children"
      ></venda-detalhes>
    </div>
  </div>
</template>

<script>
import VendaDetalhes from "./VendaDetalhes.vue";
export default {
  name: "vendaDet" + Math.random(),
  components: {
    VendaGrupos: () => import("./VendaGrupos.vue"),
    VendaDetalhes,
  },
  props: {
    filtros: Object,
    grupo: Object,
    nivel: {
      type: Number,
      default: 0,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
