var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('h3',{staticClass:"text-center"},[_c('b-spinner'),_vm._v(" Carregando...")],1)]):_vm._e(),((!_vm.historico || _vm.historico.length == 0) && !_vm.loading)?_c('EmptyState',[_c('h3',[_vm._v("Nada encontrado!")]),_c('p',[_vm._v("O paciente ainda não tem nada no histórico...")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"p-4"},_vm._l((_vm.historico),function(hist){return _c('div',{key:`hist_${hist.id}_${Math.random}`,staticClass:"histCard pt- d-flex"},[_c('div',{staticClass:"m-4 mt-5 text-grey d-flex ga-1"},[_c('h4',{staticClass:"mt-1 font-weight-bold",staticStyle:{"color":"#444"}},[_vm._v(_vm._s(_vm._f("moment")(hist.data,"DD/MM/YYYY")))])]),_c('div',{staticClass:"timelinex"}),_c('div',{staticClass:"card m-4",class:{ cadCancelado: hist.cancelado == 1 },staticStyle:{"flex":"1"}},[_c('div',{staticClass:"card-header"},[_c('i',{staticClass:"fa fa-user-md mr-1"}),_vm._v(" "+_vm._s(hist.autor)+" "),_c('div',{staticClass:"float-right"},[_c('small',[_vm._v(_vm._s(hist.clinica)+" ")])]),(hist.cancelado == 1)?_c('b-card',{staticClass:"w-100 m-2 text-danger text-left d-flex justify-space-between"},[_c('b',[_vm._v("Cancelado ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(hist.dt_cancelado,"DD/MM/YYYY HH:mm"))+" ")])]):_vm._e()],1),_c('div',{staticClass:"card-body"},[_c('ul',_vm._l((hist.itens),function(it){return _c('li',{key:`it_${it.id + Math.random}`,staticClass:"mb-3"},[_vm._v(" "+_vm._s(it.descricao)+" "),(it.exames && it.exames.medicoNome)?_c('small',[_c('br'),_c('b',[_vm._v("Médico:")]),_vm._v(" "+_vm._s(it.exames.medicoNome)+" ")]):_vm._e(),(_vm.paciente_id != it.paciente_id)?_c('small',[_c('br'),_c('b',[_vm._v("Paciente:")]),_vm._v(" "+_vm._s(it.paciente)+" ")]):_vm._e(),(
                      it.tipo_venda == 'Exame' &&
                      it.exames &&
                      it.exames.exames
                    )?_c('div',[_c('hr'),(it.exames.exames)?_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"small":"","items":it.exames.exames,"fields":[
                        { key: 'nome' },
                        {
                          key: 'valor_venda',
                          label: 'Valor',
                          class: 'text-right',
                        },
                      ]},scopedSlots:_vm._u([{key:"cell(valor_venda)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(row.item.valor_venda))+" ")]}}],null,true)}):_vm._e()],1):_vm._e()])}),0)]),_c('div',{staticClass:"card-footer d-flex justify-content-between"},[_c('div',[_c('b-icon-headset'),_vm._v(" "+_vm._s(hist.usuario))],1),_c('div',{staticClass:"d-flex"},[_c('b-btn',{staticClass:"float-right",attrs:{"variant":"none","type":"button"},on:{"click":function($event){return _vm.openPgs(hist)}}},[_c('b-icon-menu-button-wide'),_vm._v(" PG. Fornecedores ")],1),_c('b-btn',{staticClass:"float-right",attrs:{"variant":"none","type":"button"},on:{"click":function($event){return _vm.openVenda(hist)}}},[_c('b-icon-arrow-up-right-circle'),_vm._v(" Abrir Venda ")],1)],1)])]),_c('hr')])}),0)])]),_c('b-modal',{attrs:{"id":"modal-open-venda","size":"lg","hide-footer":"","hide-header":""}},[(_vm.idOpen && _vm.idOpen != '')?_c('VendaDetail',{attrs:{"vendaId":_vm.idOpen},on:{"close":function($event){return _vm.$bvModal.hide('modal-open-venda')}}}):_vm._e()],1),_c('b-modal',{attrs:{"id":"modal-open-pgs","size":"lg","hide-footer":"","title":"Pagamentos Realizados p/ Fornecedores"}},[_c('PacienteHistoricoVendaPgFornDetail',{attrs:{"historicoObj":_vm.vndObj}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }