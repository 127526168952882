<template>
  <div>
    <div
      class="py-2 d-flex align-center justify-content-center align-items-center"
    >
      <img
        src="@/assets/logo.png"
        style="max-width: 60px"
        class="mr-2"
      />
      <h1>Saúde Social</h1>
    </div>
    <hr />
    <div
      v-if="!auth"
      class="p-2"
    >
      <b-form @submit="login">
        <h4>Senha de Acesso</h4>
        <b-input
          v-model="senha"
          type="password"
          class="text-center mt-4"
          pattern="\d*"
          inputmode="numeric"
          style="height: 60px; font-size: 24px"
          @focus="keyboardUp"
          @blur="keyboardDown"
        />
        <b-button
          variant="success"
          class="mt-2 btLogin"
          block
          type="submit"
          size="lg"
          :disabled="loading"
        >
          <b-spinner
            small
            v-if="loading"
          ></b-spinner>
          Acessar</b-button
        >
      </b-form>
    </div>
    <div v-if="auth">
      <div v-if="loading">
        w <b-spinner></b-spinner>
        <b-skeleton
          v-for="n in 20"
          :key="n"
          width="100%"
          height="70"
          class="mb-4"
        />
      </div>
      <div v-if="!loading">
        <div v-if="!anamnese || !anamnese.id">
          <h4>Anamnese não encontrada</h4>
        </div>
        <div v-else>
          <anamnese-preecher :anamnese="anamnese" @reload="load"></anamnese-preecher>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnamnesePreecher from '../components/Anamnese/AnamnesePreecher.vue';
import PacienteAnamnesesLib from "../libs/PacienteAnamnesesLib";

export default {
  components: {AnamnesePreecher},
  props: {
    // id: {
    //     type: String,
    //     required: true
    // }
  },
  mounted() {
    this.anamnese_id = atob(this.$route.params.id);
    // this.load();
  },
  data() {
    return {
      anamnese_id: ``,
      anamnese: {},
      loading: false,
      auth: false,
      senha: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    keyboardUp() {
      document.querySelector(".btLogin").classList.add("keyboard-up");
    },
    keyboardDown() {
      document.querySelector(".btLogin").classList.remove("keyboard-up");
    },
    async load() {
      this.loading = true;
      try {
        let result = await PacienteAnamnesesLib.getDef({
          id: this.anamnese_id,
          senha: this.senha,
        });
        if (!result || !result.success) {
          this.$swal({
            title: "Anamnese não encontrada ou senha incorreta!",
            icon: "error",
            showConfirmButton: false,
          });
        } else {
          this.anamnese = result.data[0];
          this.auth = true;
          this.$swal({
            title: "Tudo Pronto!",
            icon: "success",
            showConfirmButton: false,
            timer: 500,
          });
        }
      } catch (error) {
        this.$swal({
          title: "Erro ao carregar anamnese",
          icon: "error",
          showConfirmButton: false,
        });
      }
      this.loading = false;
    },
    login(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.load();
    },
  },
};
</script>

<style lang="scss" scoped>
.btLogin {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  transition: bottom 0.3s;

  @media (max-width: 500px) {
    &.keyboard-up {
      bottom: 40%;
    }
  }
}
</style>
