<template>
  <div>
    <div
      v-if="loading"
      class="p-5 text-center"
    >
      <b-spinner /><br />
      aguarde...
    </div>
    <div v-if="!loading">
      <div class="row border rounded shadow p-2 mx-1 mt-1 mb-3">
        <div class="col-12">
          <div class="horarios">
            <div class="usersView">
              <i class="fa fa-eye" />
              <span
                v-for="usuario in usuariosConectados"
                :key="usuario.id"
                class="ml-1"
              >
                {{ usuario.nome }}
              </span>
            </div>
            <div class="group">
              <header><i class="far fa-calendar" /> Data</header>
              <content>{{ agendaInfo.data | moment("DD/MM/YYYY") }}</content>
            </div>
            <div class="group">
              <header><i class="far fa-clock" /> Hora</header>
              <content>{{ agendaInfo.hora_inicio }}</content>
            </div>
            <div class="group">
              <header><i class="fa fa-stethoscope" /> Especialidade</header>
              <content>{{ agendaInfo.especialidadeNome }}</content>
            </div>
            <div class="group">
              <header><i class="fa fa-user-md" /> Profissional</header>
              <content>{{ agendaInfo.EntidadeNome }}</content>
            </div>

            <div class="group">
              <header>
                <i class="fa fa-clinic-medical" />

                {{ !!agendaInfo.consultorio ? "Consultório" : "Clinica" }}
              </header>
              <content>{{ clinicaNome }}</content>
            </div>

            <div class="infos">
              <div class="group">
                <header><i class="fa fa-clock" /> Horários</header>
                <content>{{ horarios.length }}</content>
              </div>

              <div class="group">
                <header><i class="far fa-check-square" /> Confirmados</header>
                <content>{{ agendaInfo.confirmados }}</content>
              </div>
              <div class="group">
                <header><i class="fa fa-user-injured" /> Consultas</header>
                <content
                  >{{ agendaInfo.consultas }}/{{
                    agendaInfo.consulta_qtd
                  }}</content
                >
              </div>
              <div class="group">
                <header><i class="fa fa-user-check" /> Compareceu</header>
                <content>{{ agendaInfo.compareceram }}</content>
              </div>
              <div class="group">
                <header><i class="fa fa-undo" /> Retornos</header>
                <content
                  >{{ agendaInfo.retornos }}/{{
                    agendaInfo.retorno_qtd
                  }}</content
                >
              </div>
              <div class="group">
                <header><i class="fa fa-stethoscope" /> Procedimentos</header>
                <content>{{ agendaInfo.procedimentos }}</content>
              </div>
              <div class="group">
                <header><i class="fa fa-user-clock" /> Espera</header>
                <content>
                  {{ agendaInfo.fila_espera.length }}/{{
                    agendaInfo.espera_qtd || 0
                  }}</content
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 pr-1 mt-2 pt-0">
          <b-card>
            <small class="text-muted">Observações</small>
            <b-form-textarea
              v-model="agendaInfo.obs"
              style="font-size: 11px; height: 85%"
              rows="5"
              @blur="atualizaObs"
              :readonly="!user.isAdmin && !user.isGerente"
            ></b-form-textarea>
          </b-card>
        </div>
        <div
          class="col-7 pr-0 pl-2 mt-2"
          style="font-size: 10px !important; border-left: 1px solid #ccc"
        >
          <b-card>
            <label>Fila de Espera</label>
            <FilaDeEspera
              ref="FilaDeEspera"
              :filaEsperaProp="agendaInfo.fila_espera"
              :fila_espera_max="agendaInfo.espera_qtd"
              @paciente:added="handlePacienteEsperaAdded($event)"
              @paciente:removed="handlePacienteEsperaRemoved($event)"
              @paciente:updated="handlePacienteEsperaUpdated($event)"
              @paciente:agendado="handlePacienteEsperaAgendado($event)"
            />
            <b-btn
              variant="primary"
              @click="$refs.FilaDeEspera.addRowEspera()"
              size="sm"
            >
              <i class="fas fa-plus"></i> Adicionar</b-btn
            >
          </b-card>
        </div>
      </div>

      <div class="row align-items-end justify-content-center">
        <div class="col-12 col-lg-8">
          <b-alert
            style="cursor: pointer"
            @click.native="openAgendaRemarcada"
            variant="info"
            :show="agendaInfo.remarcou.remarcou"
            class="text-center m-3"
          >
            <h4>
              Agenda/Pacientes remarcada(os) para
              {{ agendaInfo.remarcou.agenda.data }}
            </h4>
            <b-avatar
              :src="formataUrl(agendaInfo.remarcou.agenda.medico_foto)"
              size="sm"
            ></b-avatar>
            {{ agendaInfo.remarcou.agenda.medico }} -
            {{ agendaInfo.remarcou.agenda.especialidade }} |
            {{ agendaInfo.remarcou.agenda.qtdPacs }} horários
            <div>
              <b>
                <small>(clique para abrir agenda)</small>
              </b>
            </div>
          </b-alert>
        </div>
      </div>
      <div class="row text-center justify-content-end">
        <div class="d-flex flex-row mb-2">
          <!-- <div v-if="!!agendaInfo.anamnese">
              <b-btn
                variant="info"
                @click="openPreencherAnamnese"
                class="text-nowrap mx-1 "
              >
                <i class="fas fa-notes-medical"></i> Preencher Anamnese
              </b-btn
              >
            </div> -->

          <div class="">
            <b-btn
              variant="success"
              @click="openConfirmationWhatsapp"
              class=""
            >
              <i class="fab fa-whatsapp"></i> Confirmação
            </b-btn>
            <b-btn
              variant="info"
              @click="printAgenda"
              class=""
            >
              <span v-if="print"><b-spinner small /></span>
              <span v-if="!print"> <b-icon-printer /> Imprimir </span>
            </b-btn>
          </div>

          <div class="">
            <!-- <b-btn
              variant="primary"
              @click="openFilaDeEspera"
              class="text-nowrap mx-1"
            >
              <i class="fas fa-users"></i> Fila de Espera
              {{ agendaInfo.fila_espera.length }}/{{
                agendaInfo.espera_qtd || 0
              }}</b-btn
            > -->
          </div>

          <div class="">
            <b-dropdown
              variant="primary"
              @show="animatedIn = false"
              @hidden="animatedIn = true"
              class="mr-4"
              v-if="['admin', 'super', 'gerente'].includes(user.perfil)"
            >
              <template #button-content>
                <i
                  :class="[
                    'fas fa-cog',
                    animatedIn ? 'animated-in' : 'animated-out',
                  ]"
                ></i>
                Opções
              </template>
              <b-dropdown-item @click="editarAgenda">
                <i class="fas fa-edit"></i> <b> Editar</b></b-dropdown-item
              >
              <b-dropdown-item @click="openRemarcarPacientes">
                <i class="fas fa-user-edit"></i> <b>Remarcar Paciente(s)</b>
              </b-dropdown-item>
              <b-dropdown-item
                @click="excluirAgenda"
                v-if="user.isAdmin"
              >
                <i class="fas fa-trash"></i> <b>Excluir Agenda</b>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <b-table
            small
            :fields="fields"
            :items="horariosFormatados"
            class="tableHorarios"
            thead-class="text-center"
            hover
            selectable
            select-mode="single"
            @row-selected="openHorario"
            ref="tableHorarios"
            stacked="sm"
            responsive="md"
            style="font-size: 11px"
          >
            <template #cell(horario)="row">
              <div class="text-center">
                {{ row.item.hora }}
              </div>
            </template>

            <template #cell(paciente)="row">
              <div class="text-left">
                {{ row.item.paciente }}
              </div>
            </template>

            <template #cell(telefone)="row">
              <div class="text-left">
                {{ row.item.telefone }}
                <span
                  v-if="row.item.telefone2 && row.item.telefone2.length > 1"
                >
                  <br />
                  {{ row.item.telefone2 }}
                </span>
              </div>
            </template>

            <template #cell(obs)="row">
              <div class="text-left">
                {{ row.item.obs ? row.item.obs.toUpperCase() : "" }}

                <span
                  v-if="
                    row.item.questionario &&
                    row.item.questionario.pvez === 'Sim'
                  "
                >
                  <hr class="pt-1 pb-1 mt-1 mb-1" />
                  <small>
                    <b>1ª VEZ</b>
                  </small>
                </span>
              </div>
            </template>

            <template #cell(agendado)="row">
              <div class="text-center">
                <b-check
                  v-model="horarios.find((x) => x.id === row.item.id).agendado"
                  disabled
                  :value="1"
                  :unchecked-value="0"
                />
              </div>
            </template>
            <template #cell(confirmou)="row">
              <div class="text-center">
                <b-check
                  v-model="horarios.find((x) => x.id === row.item.id).confirmou"
                  :value="1"
                  :unchecked-value="0"
                  @change="
                    atualizaHorario(horarios.find((x) => x.id === row.item.id))
                  "
                />
              </div>
            </template>
            <template #cell(compareceu)="row">
              <div class="text-center">
                <b-check
                  v-model="
                    horarios.find((x) => x.id === row.item.id).compareceu
                  "
                  :value="1"
                  :unchecked-value="0"
                  @change="
                    atualizaHorario(horarios.find((x) => x.id === row.item.id))
                  "
                />
              </div>
            </template>
            <template #cell(retorno)="row">
              <div class="text-center">
                <b-check
                  v-model="horarios.find((x) => x.id === row.item.id).retorno"
                  :value="1"
                  :unchecked-value="0"
                  @change="
                    atualizaHorario(horarios.find((x) => x.id === row.item.id))
                  "
                />
              </div>
            </template>
            <template #cell(usuario_responsavel)="row">
              <div
                class="text-left"
                style="min-width: 180px"
              >
                <h6
                  v-if="row.item.usuarioAberto"
                  class="bg-danger text-white"
                >
                  <small>
                    <b>
                      <b-icon-lock /> {{ row.item.usuarioAberto }}
                      <br />
                    </b>
                  </small>
                </h6>

                <small v-if="row.item.usuario_responsavel">
                  <b><b-icon-pen class="mr-1"></b-icon-pen>Editou </b><br />
                  <span class="pl-4">
                    <b-icon-person /> {{ row.item.usuario_responsavel }}</span
                  >
                </small>

                <div
                  v-if="row.item.usuarioPreencheuHorario"
                  class="text-left"
                >
                  <small>
                    <b
                      ><b-icon-check-circle class="mr-1"></b-icon-check-circle>
                      Agendou </b
                    ><br />
                    <span class="pl-4">
                      <b-icon-person />
                      {{ row.item.usuarioPreencheuHorario }}
                    </span>
                  </small>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-confirmacao-whatsapp"
      hide-footer
      title="Confirmação de consultas via Whatsapp"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
    >
      <whatsapp-sender
        :referenceId="agendaInfo.id"
        :listOfContacts="
          horariosFormatados.map((it) => ({
            name: it.paciente,
            number: it.telefone,
            number2: it.telefone2,
          }))
        "
        :typeOfSend="'Agenda'"
      ></whatsapp-sender>
    </b-modal>

    <b-modal
      id="modal-horario"
      modal-class="modalMaior"
      size="lg"
      title="Alterar Horário"
      @hidden="closeHorario"
      content-class="modalMaior"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <agenda-horario-form
        :horario="horarioSelecionado"
        :agendaInfo="agendaInfo"
        @atualizaHorario="atualizaHorario"
        @reSinc="reSinc"
        @closeHorario="closeHorario"
      />
    </b-modal>

    <b-modal
      id="modal-remarcar"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title>
        <i class="fas fa-user-edit"></i> Remarcar Pacietes</template
      >
      <AgendaRemarcarPacientes
        ref="AgendaRemarcarPacientes"
        @remarcou="remarcouPacientes($event)"
        :horarios="horariosFormatados"
        :agendaAnterior="agendaInfo"
      />
      <template #modal-footer="{ cancel }">
        <b-btn
          variant="primary"
          @click="$refs.AgendaRemarcarPacientes.salvar()"
        >
          <i class="fas fa-save"></i> Remarcar</b-btn
        >
        <b-btn
          variant="secondary"
          @click="cancel"
        >
          <i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </template>
    </b-modal>

    <b-modal
      id="modal-agenda-form"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title>
        <i class="fas fa-edit"></i> Editar Agenda
      </template>
      <AgendaForm
        ref="AgendaForm"
        @success="editouAgenda"
        :agendaEdit="agendaInfo"
      />
      <template #modal-footer="{ cancel }">
        <b-btn
          variant="primary"
          @click="$refs.AgendaForm.salvarAgenda()"
        >
          <i class="fas fa-save"></i> Salvar</b-btn
        >
        <b-btn
          variant="secondary"
          @click="cancel"
        >
          <i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </template>
    </b-modal>

    <b-modal
      id="modal-fila-espera"
      no-fade
      size="lg"
    >
      <template #modal-title>
        <i class="fas fa-users"></i> Fila de Espera
        {{ agendaInfo.fila_espera.length }}/{{
          agendaInfo.espera_qtd ? agendaInfo.espera_qtd : 0
        }}
      </template>
      <FilaDeEspera
        ref="FilaDeEspera"
        :filaEsperaProp="agendaInfo.fila_espera"
        :fila_espera_max="agendaInfo.espera_qtd"
        @paciente:added="handlePacienteEsperaAdded($event)"
        @paciente:removed="handlePacienteEsperaRemoved($event)"
        @paciente:updated="handlePacienteEsperaUpdated($event)"
        @paciente:agendado="handlePacienteEsperaAgendado($event)"
      />
      <template #modal-footer="{ cancel }">
        <b-btn
          variant="primary"
          @click="$refs.FilaDeEspera.addRowEspera()"
          size="sm"
        >
          <i class="fas fa-plus"></i> Adicionar</b-btn
        >
        <b-btn
          variant="secondary"
          @click="cancel()"
          size="sm"
        >
          <i class="fas fa-ban"></i> Fechar</b-btn
        >
      </template>
    </b-modal>
    <agenda-print
      :idAgenda="idAgenda"
      v-if="print"
      @close="closePrint"
    />
  </div>
</template>

<script>
import moment from "moment";
import AgendasLib from "@/libs/AgendasLib.js";
import FilesLib from "@/libs/FilesLib";
import WhatsappSender from "../Whatsapp/WhatsappSender.vue";

export default {
  components: {
    AgendaHorarioForm: () => import("./AgendaHorarioForm.vue"),
    AgendaRemarcarPacientes: () => import("./AgendaRemarcarPacientes.vue"),
    AgendaForm: () => import("./AgendasForm.vue"),
    FilaDeEspera: () => import("./FilaDeEspera.vue"),
    AgendaPrint: () => import("./AgendaPrint.vue"),
    WhatsappSender,
  },
  props: {
    idAgenda: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
      usuariosConectados: [],
      agendaInfo: {},
      horarios: [],
      animatedIn: true,
      user: this.$store.state.auth.user,
      fields: [
        { key: "horario", label: "Horário" },
        { key: "paciente", label: "Paciente", class: "text-left" },
        { key: "telefone", label: "Telefone", class: "text-left" },
        { key: "idade", label: "Idade", class: "text-center" },
        { key: "agendado", label: "Agendado" },
        { key: "confirmou", label: "Confirmou" },
        { key: "compareceu", label: "Compareceu" },
        { key: "retorno", label: "Retorno" },
        { key: "obs", label: "Observações" },
        {
          key: "usuario_responsavel",
          label: "Responsável",
          class: "text-center",
        },
      ],
      horarioSelecionado: null,
      token: this.$store.state.auth.user.token,
      print: false,
    };
  },
  sockets: {
    connect() {
      console.log("conectado");

      this.$socket.emit("agenda:abrirHorarios", this.idAgenda);
    },
    usuariosConectados(usuarios) {
      this.usuariosConectados = usuarios;
      // console.log("usuario conectado", usuarios);
    },
    agendaInfo(data) {
      if (!this.horarioSelecionado) {
        this.agendaInfo = data;
        this.$emit("alterou:agenda", data);
        console.log("agenda info", data);
      }
    },
    horarios(data) {
      //console.log("RECEBEU HORARIOS", data);
      if (!this.horarioSelecionado) {
        this.horarios = data
          .map((hora) => {
            //console.log('hora',moment(moment(this.agendaInfo.data).format("YYYY-MM-DD ") + hora.horario).format("HH:mm"))
            return {
              hora: moment(
                moment(this.agendaInfo.data).format("YYYY-MM-DD ") +
                  hora.horario
              ).format("HH:mm"),
              ...hora,
            };
          })
          .sort((a, b) => a.horario - b.horario);
      }
      this.loading = false;
    },
  },
  computed: {
    horariosFormatados: function () {
      return this.horarios.map((item) => {
        return {
          ...item,
          _rowVariant:
            item.retorno == 1
              ? "primary"
              : item.compareceu == 1
              ? "success"
              : item.confirmou == 1
              ? "info"
              : "",
        };
      });
    },
    clinicaNome() {
      if (this.agendaInfo?.consultorio) {
        return (
          this.agendaInfo?.consultorioEndereco?.nome ||
          "Nenhum consultório cadastrado para este médico"
        );
      } else {
        return this.agendaInfo?.clinic;
      }
    },
    //   return this.agendaInfo?.consultorio
    //     ? this.agendaInfo.consultorioEndereco.nome
    //       ? this.agendaInfo.consultorioEndereco.nome
    //       : "Nenhum consultório cadastrado para este médico"
    //     : this.agendaInfo.clinic;
    // },
  },
  mounted() {
    console.log("user", this.user);
    this.$socket.emit("agenda:abrirHorarios", this.idAgenda);
  },
  methods: {
    openConfirmationWhatsapp() {
      this.$bvModal.show("modal-confirmacao-whatsapp");
    },
    excluirAgenda() {
      this.$swal({
        title: "Tem certeza?",
        text: "Tem certeza que deseja excluir esta agenda?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        icon: "question",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const res = await AgendasLib.del({ id: this.idAgenda });

          if (res.data && res.data.success) {
            this.$socket.emit("agenda:info", this.idAgenda);
            this.$emit("close");
            this.$swal
              .fire({
                title: "Agenda excluída com sucesso!",
                icon: "success",
                toast: true,
                timer: 1500,
                position: "top-end",
                showConfirmButton: false,
              })
              .then(() => {});
          } else {
            this.$swal.fire({
              title: "Erro ao excluir agenda!",
              icon: "error",
              toast: true,
              timer: 1500,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        }
      });
    },
    closePrint() {
      this.print = false;
    },
    printAgenda() {
      this.print = true;
    },
    atualizaObs() {
      this.$socket.emit("agenda:atualizaObs", this.agendaInfo);
    },
    formataUrl(url) {
      return FilesLib.downlaodFile(url, this.token);
    },
    atualizaHorario(horario, close = true) {
      console.log("atualizou horario", horario);
      delete horario.hora;
      delete horario._rowVariant;
      this.$socket.emit("agenda:atualizaHorario", horario);
      //this.$emit("alterou:agenda");
      if (close) {
        this.horarioSelecionado = null;

        this.$bvModal.hide("modal-horario");
      }
      this.$socket.emit("agenda:abrirHorarios", this.idAgenda);
    },
    reSinc(horario) {
      this.$socket.emit("agenda:resinc", horario);
    },

    openHorario(row) {
      if (row.length > 0) {
        this.horarioSelecionado = row[0];
        this.$bvModal.show("modal-horario");
      }
    },
    openRemarcarPacientes() {
      // if (this.agendaInfo.remarcou.remarcou) {
      //   this.showToast(
      //     "error",
      //     `Essa agenda já foi remarcada para a data ${this.agendaInfo.remarcou.agenda.data} - ${this.agendaInfo.remarcou.agenda.medico} `,
      //     3500
      //   );
      //   return;
      // }
      this.$bvModal.show("modal-remarcar");
    },
    openFilaDeEspera() {
      this.$bvModal.show("modal-fila-espera");
    },
    async remarcouPacientes(agenda) {
      this.$bvModal.hide("modal-remarcar");

      this.$emit("alterou:remarcou", agenda);
      this.$socket.emit("agenda:info", this.idAgenda);
    },
    async openAgendaRemarcada() {
      await this.toastConfirmacao(
        () => {
          this.$emit(
            "abrirAgendaRemarcada",
            this.agendaInfo.remarcou.agenda.id
          );
        },
        () => {},
        "Abrir agenda remarcada?",
        "Isso irá fechar a agenda atual e abrir a remarcada, tem certeza que deseja continuar?",
        "question",
        "Sim, Continuar!"
      );
    },
    async editouAgenda() {
      this.$forceUpdate();
      await this.$nextTick();
      this.$bvModal.hide("modal-agenda-form");
      // this.$bvModal.hide("horarios-table");

      //this.$emit("alterou:agenda");
      this.$socket.emit("agenda:info", this.idAgenda);
    },
    async handlePacienteEsperaAdded(espera) {
      this.$forceUpdate();
      await this.$nextTick();
      if (typeof espera === "object") {
        this.agendaInfo.fila_espera.push(espera);
      }

      // console.log("PacienteAdded: ", this.agendaInfo.fila_espera);
      try {
        await AgendasLib.store(this.agendaInfo);
        this.showToast("success", "Salvo com sucesso!");
        //this.$emit("alterou:fila_espera");
        this.$socket.emit("agenda:info", this.idAgenda);
      } catch (error) {
        console.log(error);
        this.showToast("error", "Ocorreu algum erro!");
      }
    },
    async handlePacienteEsperaRemoved(index) {
      this.$forceUpdate();
      await this.$nextTick();
      // console.log(this.agendaInfo.fila_espera, "index Enviado", index);
      // console.log(
      //   "index da agenda apagada: ",
      //   this.agendaInfo.fila_espera[index]
      // );

      this.agendaInfo.fila_espera.splice(index, 1);

      try {
        await AgendasLib.store(this.agendaInfo);
        this.showToast("success", "Apagado com sucesso!");
        // this.$emit("alterou:fila_espera");
        this.$socket.emit("agenda:info", this.idAgenda);
      } catch (error) {
        console.log(error);
        this.showToast("error", "Ocorreu algum erro!");
      }
    },
    async handlePacienteEsperaUpdated(_espera) {
      let { index, ...espera } = _espera;
      // console.log("PacienteUpdated: ", this.agendaInfo.fila_espera);

      this.$set(this.agendaInfo.fila_espera, index, espera);

      try {
        await AgendasLib.store(this.agendaInfo);
        this.showToast("success", "Atualizado com sucesso!");
        this.$emit("alterou:fila_espera");
        this.$socket.emit("agenda:info", this.idAgenda);
      } catch (error) {
        console.log(error);
        this.showToast("error", "Ocorreu algum erro!");
      }
    },
    async handlePacienteEsperaAgendado(_espera) {
      // console.log(_espera);
      let { index, ...espera } = _espera;
      this.$set(this.agendaInfo.fila_espera[index], "agendado", 1);
      this.agendaInfo.consulta_qtd = parseInt(this.agendaInfo.consulta_qtd) + 1;
      this.agendaInfo.total = parseInt(this.agendaInfo.total) + 1;
      await this.handlePacienteEsperaUpdated({
        ...this.agendaInfo.fila_espera[index],
        index,
      });

      let horario = {
        id_agenda: this.agendaInfo.id,
        horario: espera.horario,
        paciente: espera.paciente,
        telefone: espera.telefone,
        obs: "Horário gerado a partir da fila de espera",
        agendado: 1,
        fila_espera: 1,
      };
      await this.atualizaHorario(horario);

      this.$bvModal.hide("modal-fila-espera");
    },
    editarAgenda() {
      this.$bvModal.show("modal-agenda-form");
    },
    closeHorario(e) {
      this.$bvModal.hide("modal-horario");
      let obj = Object.assign({}, { ...this.horarioSelecionado });

      console.log("close call", obj);
      if (!obj.user_aberto_id || obj.user_aberto_id === this.user.id) {
        console.log("call close DESTRAVA");

        this.$socket.emit("agenda:travarHorario", {
          horario_id: obj.id,
          user_id: this.user.id,
          type: "destravar",
        });
      }

      this.horarioSelecionado = null;
      this.$refs.tableHorarios.clearSelected();

      if (e) e.preventDefault();
    },
  },
  beforeDestroy() {
    // console.log('desconetou usuário',  {
    //   nome: this.user.nome,
    //   id: this.user.id,
    // })
    this.$socket.emit("agenda:desconectarUsuario", {
      nome: this.user.nome,
      id: this.user.id,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/agenda.scss";
.animated-in {
  -webkit-animation-name: animate;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
}

.animated-out {
  -webkit-animation-name: animateOut;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: backwards;
}

@-webkit-keyframes animate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
  // 0%{
  //   transform: rotate(0deg);
  // }
  // 100%{
  //   transform: rotate(360deg);
  // }
}

@-webkit-keyframes animateOut {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
  // 0%{
  //   transform: rotate(0deg);
  // }
  // 100%{
  //   transform: rotate(360deg);
  // }
}
</style>
