<template>
  <div class="container pb-5">
    <barra-crud
      :title="`Agenda`"
      :busca="false"
      v-if="false"
    />

    <div class="row">
      <div
        class="col-12 p-5"
        v-if="!loading"
      >
        <small>
          <b-form-group
            label=""
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-1"
              v-model="filtroData"
              @changed="carregar"
              :options="optionsData"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </small>
        <vc-date-picker
          v-if="!loading"
          v-model="dataSelecionada"
          @input="carregar"
          mode="date"
          color="red"
          :rows="1"
          :columns="$screens({ default: 1, md: 2, lg: 3, xl: 4 })"
          spellcheck="true"
          :is-expanded="$screens({ default: true, xl: true })"
          is-required
          :is-range="filtroData == 2"
          :locale="{ firstDayOfWeek: 1, masks: { weekdays: 'W' } }"
          :attributes="[
            {
              key: 'today',
              highlight: { color: 'blue', fillMode: 'outline' },
              dates: new Date(),
            },
          ]"
        />
      </div>
      <div
        v-if="loading"
        class="col-12 text-centertext-center p-5"
      >
        <b-spinner /> <br />
        aguarde...
      </div>
      <div
        class="col-12 col-md-4 col-lg-3 text-left pr-0"
        v-if="!loading"
      >
        <h4 class="text-center"><i class="fa fa-filter" /> Resumo / Filtro</h4>
        <hr style="margin-top: 10px" />
        <div class="pr-4">
          <label><b-icon-shop /> Clínicas</label>
          <list-check
            :withTodos="true"
            v-model="listaResumida.Clinicas"
            @change="
              (e) => {
                changeClinicasResumo(e);
              }
            "
            :label="'sigla'"
            :multiple="false"
          />
          <hr />
          <label><i class="fa fa-stethoscope" /> Especialidades</label>
          <list-check
            :withTodos="true"
            v-model="listaResumida.Especialidades"
            @change="
              (e) => {
                changeEspecialidadesResumo(e);
              }
            "
            :label="'especialidade'"
            :multiple="false"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-8 col-lg-9 pl-0"
        v-if="!loading"
      >
        <b-tabs
          fill
          v-model="tabSelect"
        >
          <b-tab
            title="Agendas"
            value="Agendas"
          >
            <template #title> <b-icon-calendar /> Agendas </template>

            <div class="row mt-3">
              <div
                class="col-11 d-flex flex-wrap flex-sm-nowrap"
                v-if="!user.isMedico"
              >
                <b-form-group class="flex w-50 pr-2 pl-2">
                  <label><i class="fas fa-user-nurse"></i> Médico</label>
                  <b-spinner
                    v-if="loadingEntidades"
                    class="align-baseline"
                    small
                    size="sm"
                    grow
                  />
                  <select-paciente
                    :hideBackdrop="false"
                    :tipo="['medico']"
                    :showBotaoAdd="false"
                    setNullOnEmpty
                    :showBotaoEdit="false"
                    :selecionado="filtros.Entidade.valor"
                    @paciente="changeMedicoFiltro"
                    :minLength="0"
                  ></select-paciente>
                </b-form-group>
                <b-form-group class="flex w-50 pr-2 pl-2">
                  <label
                    ><i class="fas fa-book-medical"></i> Especialidade</label
                  >
                  <b-spinner
                    v-if="loadingEspecialidades"
                    class="align-baseline"
                    small
                    size="sm"
                    grow
                  />

                  <v-select
                    aria-autocomplete=""
                    v-model="filtros.especialidade.valor"
                    class="style-chooser"
                    :reduce="
                      (especialidadesOptions) => especialidadesOptions.code
                    "
                    :options="especialidadesOptions"
                    @input="carregar"
                  />
                </b-form-group>
                <b-form-group class="flex w-50 pr-2 pl-2">
                  <select-clinicas
                    :withConsultorio="true"
                    v-model="filtros.clinica.valor"
                    :autoBlock="false"
                    @input="carregar"
                  />
                </b-form-group>
              </div>

              <div class="col-12 text-right">
                <b-btn
                  :disabled="loading"
                  @click="carregar"
                  size=""
                  variant="secondary-rounded"
                >
                  <i
                    v-if="!loading"
                    class="fas fa-sync-alt"
                  ></i>
                  <b-spinner
                    small
                    v-if="loading"
                  ></b-spinner>
                </b-btn>
                <b-radio-group
                  button
                  button-variant="outline-primary"
                  buttons
                  v-model="tipoListagem"
                >
                  <b-radio value="list"><b-icon-list /> </b-radio>
                  <b-radio value="card"><b-icon-card-text /> </b-radio>
                </b-radio-group>
              </div>

              <div
                class="col-12"
                v-if="!loading && tipoListagem == 'list'"
              >
                <table
                  class="table table-sm table-hover"
                  style="font-size: 11px"
                >
                  <thead>
                    <tr>
                      <th>Clínica</th>
                      <th>Médico</th>
                      <th>Consultas</th>
                      <th>Retornos</th>
                      <th style="max-width: 200px">Observação</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="grupo in listaAgrupada"
                    :key="grupo.grupo"
                  >
                    <tr>
                      <td
                        colspan="88"
                        class="text-left"
                      >
                        <b>{{ grupo.grupo }}</b>
                      </td>
                    </tr>
                    <tr
                      v-for="agenda in grupo.lista"
                      :key="agenda.id"
                      class="text-left cursor"
                      @click="openAgenda(agenda.id)"
                    >
                      <td>
                        {{
                          !!agenda.consultorio
                            ? "Consultorio Médico"
                            : agenda.clinic
                        }}
                      </td>
                      <td>
                        {{ agenda.EntidadeNome }}
                      </td>
                      <td class="text-center">
                        {{ agenda.agendados }} / {{ agenda.consulta_qtd }}
                      </td>
                      <td class="text-center">
                        {{ agenda.retornos }}/{{ agenda.retorno_qtd }}
                      </td>
                      <td style="max-width: 200px">
                        {{ agenda.obs }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="col-12"
                v-if="!loading && tipoListagem == 'card'"
              >
                <div
                  v-for="grupo in listaAgrupada"
                  :key="grupo.grupo"
                >
                  <h3>{{ grupo.grupo }}</h3>
                  <hr />
                  <div class="agenda">
                    <agenda-card
                      v-for="agenda in grupo.lista"
                      :key="agenda.id"
                      :agenda="agenda"
                      :mostraData="filtroData != 1"
                      @click.native="openAgenda(agenda.id)"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="!loading && (!lista || lista.length <= 0)"
                class="col-12 text-center p-5"
              >
                <h1>:(</h1>
                nenhuma agenda encontrada.
              </div>
            </div>
          </b-tab>
          <b-tab
            v-if="!user.isMedico"
            title="Solicitações / Pendências"
            value="Solicitacoes"
          >
            <template #title> <b-icon-journal-bookmark /> Pendências </template>
            <div
              class="row mt-3"
              v-if="solicitacoes"
            >
              <div class="col-12">
                <agendas-solicitacoes
                  :dataSelecionada="dataSelecionada"
                  @abrirAgenda="openAgenda($event)"
                  :filtroData="filtroData"
                ></agendas-solicitacoes>
              </div></div
          ></b-tab>
          <b-tab>
            <template #title><b-icon-person-badge /> Buscar Paciente</template>
            <div class="p-3">
              <h4 class="pt-4">
                <b-icon-search /> Buscar agendamentos por Paciente
              </h4>
              <hr />
              <b-input
                debounce="900"
                v-model="buscaPaciente"
                placeholder="Digite o nome do paciente"
              />
              <div class="mt-2">
                <b-skeleton-table v-if="buscandoPaciente"></b-skeleton-table>
                <b-table
                  :fields="[
                    { key: 'data', label: 'Data' },
                    { key: 'horario', label: 'Hora' },
                    { key: 'paciente', label: 'Paciente' },
                    { key: 'telefone', label: 'Telefone' },
                    { key: 'medico', label: 'Médico' },
                    { key: 'especialidade', label: 'Especialidade' },
                    { key: 'clinica', label: 'Clínica' },
                    { key: 'compareceu', label: 'Infos' },
                  ]"
                  :items="listaBuscaPaciente"
                  v-if="!buscandoPaciente"
                  small
                  style="font-size: 10px; text-align: left"
                  hover
                >
                  <template #cell(compareceu)="data">
                    <b-badge
                      :variant="data.value === 1 ? 'success' : 'danger'"
                      >{{
                        data.value === 1 ? "Comapareceu" : "Não Compareceu"
                      }}</b-badge
                    >
                    <b-badge
                      class="mx-1"
                      :variant="
                        data.item.confirmou === 1 ? 'success' : 'danger'
                      "
                      >{{
                        data.item.confirmou === 1
                          ? "Confirmou"
                          : "Não Confirmou"
                      }}</b-badge
                    >
                  </template>
                  <template #cell(horario)="data">
                    {{ data.value | moment("HH:mm") }}
                  </template>
                  <template #cell(data)="data">
                    {{ data.value | moment("DD/MM/YYYY") }}
                  </template>
                </b-table>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal
      id="horarios-table"
      title=""
      modal-class="modalMaior"
      size="xl"
      hide-footer
      style="max-width: 90%"
    >
      <template #modal-title>
        {{ `${!atendimento ? "Agenda" : "Fila de Atendimento"}` }}
      </template>
      <agenda-horarios
        ref="agendaHorarios"
        @alterou="carregar"
        :idAgenda="id_agenda"
        @alterou:remarcou="alterouRemarcou($event)"
        @alterou:agenda="alterouAgenda($event)"
        @alterou:fila_espera="alterouFilaEspera"
        @abrirAgendaRemarcada="abrirAgendaRemarcada($event)"
        v-if="!atendimento"
        @close="closeAgenda"
      />
      <atendimento-lista
        v-if="atendimento"
        :agendaId="id_agenda"
        :agendaInfo="agendaInfo"
        @changeAgenda="onChangeAgenda($event)"
      />
    </b-modal>
  </div>
</template>

<script>
import AgendaCard from "../../components/Agendas/AgendaCard.vue";
import AgendasSolicitacoes from "../../components/Agendas/Solicitacoes/AgendasSolicitacoes.vue";
import AgendasLib from "../../libs/AgendasLib.js";
import EmpresasLib from "../../libs/EmpresasLib";
import BarraCrud from "../../components/common/BarraCrud.vue";
import EspecialidadesLib from "../../libs/EspecialidadesLib";
import EntidadesLib from "../../libs/EntidadesLib";
import moment from "moment";
import AgendaHorarios from "../../components/Agendas/AgendaHorarios.vue";
import SelectClinicas from "../../components/common/SelectClinicas.vue";
import SelectPaciente from "../../components/common/SelectPaciente.vue";
import AtendimentoLista from "./atendimento/AtendimentoLista.vue";
import ListCheck from "../../components/common/ListCheck.vue";

export default {
  components: {
    BarraCrud,
    AgendaCard,
    AgendaHorarios,
    AgendasSolicitacoes,
    AtendimentoLista,
    SelectClinicas,
    SelectPaciente,
    ListCheck,
  },
  async mounted() {
    await this.$nextTick();
    this.$forceUpdate();
    this.carregaEspecialidades();
    this.carregar();

    this.empresas = (await EmpresasLib.getEmpresasGrupoAPI(false)) || [];
  },
  props: {
    solicitacoes: {
      type: Boolean,
      default: true,
    },
    atendimento: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buscaPaciente: "",
      buscandoPaciente: false,
      listaBuscaPaciente: [],
      tabSelect: "Agendas",
      tipoListagem: "card",
      loading: false,
      dataSelecionada: new Date(),
      filtros: {
        Entidade: {
          ativo: false,
          valor: "",
        },
        especialidade: {
          ativo: false,
          valor: "",
        },
        clinica: {
          ativo: false,
          valor: "",
        },
      },
      id_agenda: null,
      agendaInfo: null,
      lista: [],
      listaRaw: [],
      filterOn: [],
      entidadesOptions: [],
      especialidadesOptions: [],
      loadingEntidades: false,
      loadingEspecialidades: false,
      filtroData: 1,
      optionsData: [
        { text: "Sem Data", value: 0 },
        { text: "Com Data", value: 1 },
        { text: "Periodo", value: 2 },
      ],
      listaResumida: {
        Medicos: [],
        Clinicas: [],
        Especialidades: [],
      },
      empresas: [],
      listaAgrupada: [],
    };
  },
  watch: {
    dataSelecionada: function () {
      //console.log('dataSelecionada changed', this.dataSelecionada);
      this.carregar();
    },
    atendimento: function () {
      this.carregar();
    },
    buscaPaciente: function () {
      this.buscarHorarioPorPaciente();
    },
    // "filtros.Entidade.valor": {
    //   deep: true,
    //   async handler() {
    //      console.log('filtros.especialidade.valor changed', this.filtros.Entidade.valor);
    //     await this.carregar();
    //   },
    // },
    // "filtros.especialidade.valor": {
    //   deep: true,
    //   async handler() {
    //       console.log('filtros.especialidade.valor changed', this.filtros.especialidade.valor);
    //     await this.carregar();
    //   },
    // },
    // "filtros.clinica.valor": {
    //   deep: true,
    //    handler() {
    //      console.log('filtros.clinica.valor changed', this.filtros.clinica.valor);
    //      this.carregar();
    //   },
    // },
    filtroData: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
  },
  computed: {
    // listaAgrupada: function () {
    //   return this.lista;
    // },
  },
  methods: {
    async buscarHorarioPorPaciente() {
      this.buscandoPaciente = true;
      this.listaBuscaPaciente = [];
      try {
        this.listaBuscaPaciente = await AgendasLib.buscaHorarioPorPaciente({
          geral: this.buscaPaciente,
        });
        console.log("listaBuscaPaciente", this.listaBuscaPaciente);
      } catch (error) {
        console.log(error);
      }
      this.buscandoPaciente = false;
    },
    changeMedicoFiltro(medico) {
      console.log(1111111111111111111111, medico);
      if (
        medico &&
        medico.id &&
        (!this.filtros.Entidade.valor ||
          this.filtros.Entidade.valor.id != medico.id)
      ) {
        this.filtros.Entidade.valor = medico;
        this.carregar();
      } else if (!medico) {
        this.filtros.Entidade.valor = null;
        this.carregar();
      }
    },
    changeEspecialidadesResumo(e) {
      this.listaResumida.Especialidades = e;
      this.filtrar();
      this.$forceUpdate();
    },
    changeClinicasResumo(e) {
      this.listaResumida.Clinicas = e;
      this.filtrar();
      this.$forceUpdate();
    },
    filtrar() {
      let lst = JSON.parse(JSON.stringify(this.listaRaw));
      if (this.listaResumida.Clinicas.some((x) => x.checked === true)) {
        lst = lst.filter(
          (x) =>
            (this.listaResumida.Clinicas.filter((d) => d.checked === true).some(
              (a) => a.id == -1
            ) &&
              x.consultorio == 1) ||
            this.listaResumida.Clinicas.filter((d) => d.checked === true).some(
              (a) => a.id == x.clinica_id
            )
        );
      }
      if (this.listaResumida.Especialidades.some((x) => x.checked === true)) {
        lst = lst.filter((x) =>
          this.listaResumida.Especialidades.filter(
            (a) => a.checked === true
          ).some((a) => a.especialidade === x.especialidadeNome)
        );
      }

      console.log("filtrada", lst);
      this.lista = lst;
      this.listaAgrupada = lst
        .reduce((ret, vl) => {
          let grupo = ret.find((x) => x.grupo == vl.especialidadeNome);
          if (!grupo) {
            ret.push({
              lista: [vl],
              grupo: vl.especialidadeNome,
            });
          } else {
            grupo.lista.push(vl);
          }
          return ret;
        }, [])

        .sort((a, b) => {
          // console.log("a:", a, "b:", b);
          return a.grupo.toLowerCase().indexOf("oftalmo") > -1
            ? -1
            : b.grupo.toLowerCase().indexOf("oftalmo") > -1
            ? 1
            : a.grupo
                .trim()
                .toLowerCase()
                .localeCompare(b.grupo.toLowerCase().trim());
        });
      this.$forceUpdate();
    },
    async carregaEntidades() {
      this.loadingEntidades = true;
      const Entidades = (await EntidadesLib.get("medico")).entidades;

      this.EntidadesOptions = Entidades.map((med) => {
        return { label: med.razao_social, code: med.id };
      });
      this.loadingEntidades = false;
    },
    async onChangeAgenda(agendaChanged) {
      this.$forceUpdate();
      await this.$nextTick();

      if (
        !agendaChanged ||
        !agendaChanged.id ||
        agendaChanged.id !== this.agendaInfo.id
      )
        return;
      this.agendaInfo = Object.assign(this.agendaInfo, agendaChanged);
      //  console.log(agendaChanged, this.agendaInfo);
    },
    async carregaEspecialidades() {
      this.loadingEspecialidades = true;
      const especialidade = await EspecialidadesLib.get();

      this.especialidadesOptions = especialidade.map((espec) => {
        return { label: espec.especialidade, code: espec.id };
      });
      this.loadingEspecialidades = false;
    },
    async alterouAgenda(agenda) {
      // console.log('alterou agenda', agenda);
      if (agenda && agenda.id) {
        const idxAgenda = this.lista.findIndex((a) => a.id == agenda.id);
        //  console.log('achou o index da agenda', idxAgenda);
        if (idxAgenda > -1) {
          this.lista[idxAgenda] = Object.assign(this.lista[idxAgenda], agenda);
        }
      }
      // await this.carregar();
      // this.$bvModal.hide("horarios-table");
    },
    async alterouFilaEspera() {
      // await this.carregar();
    },
    async alterouRemarcou(agendaRemarcada = {}) {
      await this.carregar();
      if (typeof agendaRemarcada.id == "number") {
        await this.toastConfirmacao(
          () => {
            this.$bvModal.hide("horarios-table");
            this.openAgenda(agendaRemarcada.id);
          },
          () => {},
          "Abrir agenda remarcada?",
          `A agenda foi remarcada para ${agendaRemarcada.data}, deseja abrir a agenda remarcada?`,
          "question",
          "Sim, Abrir."
        );
        // this.$socket.emit("agenda:abrirHorarios");
      }
    },
    async carregar() {
      // this.$bvModal.hide("horarios-table");

      if (this.loading) return;
      this.loading = true;
      await this.$nextTick();
      this.$forceUpdate();

      const DATA_DE = moment(
        this.filtroData == 0
          ? "2000-01-01"
          : this.filtroData == 2
          ? this.dataSelecionada.start
          : this.dataSelecionada
      ).format("YYYY-MM-DD");

      const DATA_ATE = moment(
        this.filtroData == 0
          ? "2099-01-01"
          : this.filtroData == 2
          ? this.dataSelecionada.end
          : this.dataSelecionada
      ).format("YYYY-MM-DD");

      const ENTIDADE = this.user.isMedico
        ? this.user?.entidade_id
        : this.filtros.Entidade.valor
        ? this.filtros.Entidade.valor.id
        : null;

      try {
        this.listaRaw = [];
        this.listaRaw = (
          await AgendasLib.get(
            DATA_DE,
            DATA_ATE,
            ENTIDADE,
            this.filtros.especialidade.valor,
            this.filtros.clinica.valor,
            this.atendimento ? true : false
          )
        ).sort((a, b) => {
          return a.EntidadeNome > b.EntidadeNome ? 1 : -1;
        });

        console.log("listaage", this.lista);
        this.listaResumida.Clinicas = this.listaRaw.reduce((ret, vl) => {
          let has = ret.find((x) => x.id == vl.clinica_id);
          if (!has) {
            let emp = this.empresas.find((x) => x.id == vl.clinica_id);
            if (emp) {
              ret.push({
                id: vl.clinica_id,
                sigla: emp.sigla,
              });
            }
          }
          return ret;
        }, []);
        if (this.listaRaw.some((x) => x.consultorio == 1)) {
          this.listaResumida.Clinicas.push({
            id: -1,
            sigla: "Consultório Médico",
          });
        }

        this.listaResumida.Medicos = this.listaRaw.reduce((ret, vl) => {
          let has = ret.find((x) => x.nome == vl.EntidadeNome);
          if (!has) {
            let emp = this.empresas.find((x) => x.nome == vl.EntidadeNome);
            if (emp) {
              ret.push({
                nome: vl.EntidadeNome,
              });
            }
          }
          return ret;
        }, []);

        this.listaResumida.Especialidades = this.listaRaw
          .reduce((ret, vl) => {
            let has = ret.find((x) => x.especialidade == vl.especialidadeNome);
            if (!has) {
              ret.push({
                especialidade: vl.especialidadeNome,
              });
            }
            return ret;
          }, [])
          .sort((a, b) => {
            return a.especialidade > b.especialidade ? 1 : -1;
          });

        console.log("especs", this.listaResumida.Especialidades);

        this.filtrar();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    abrirAgendaRemarcada(id) {
      // this.$refs.agendaHorarios.descontarUsu()
      this.$bvModal.hide("horarios-table");
      this.openAgenda(id);
    },
    openAgenda(id) {
      this.$bvModal.hide("horarios-table");
      this.id_agenda = id;
      this.agendaInfo = this.lista.find((x) => x.id == this.id_agenda);
      this.$bvModal.show("horarios-table");
    },
    closeAgenda() {
      this.$bvModal.hide("horarios-table");
      this.carregar();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/agenda.scss";
.cursor {
  cursor: pointer;
}
</style>
