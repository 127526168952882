var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('vue-simple-suggest',{ref:"select",staticStyle:{"flex":"1"},attrs:{"list":_vm.cidLista,"disabled":_vm.disabled,"filter-by-query":false,"autocomplete":"off","min-length":_vm.minLength,"debounce":500,"display-attribute":"descricao","mode":"select","controls":{
      selectionUp: [38, 33],
      selectionDown: [40, 34],
      select: [13, 36],
      showList: [40],
      hideList: [27, 35, 13],
    },"placeholder":"digite para buscar uma doença ou CID"},on:{"select":function($event){_vm.$emit('select', $event);
      _vm.onInput($event);},"input":_vm.onInput2,"request-start":function($event){_vm.loading = true},"request-done":function($event){_vm.loading = false}},scopedSlots:_vm._u([{key:"suggestion-item",fn:function({ suggestion }){return _c('div',{},[_c('div',[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(suggestion.id)+" - "+_vm._s(suggestion.descricao)+" ")]),_c('small',[_c('div',{staticClass:"d-flex justify-content-between"},[(suggestion.id)?_c('small',[_vm._v("Código.: "+_vm._s(suggestion.id)+" ")]):_vm._e()])])])])}}]),model:{value:(_vm.cidSel),callback:function ($$v) {_vm.cidSel=$$v},expression:"cidSel"}}),_c('b-btn',{attrs:{"variant":"info"},on:{"click":_vm.openFinder}},[_c('b-icon-book-half')],1),_c('b-modal',{attrs:{"id":"modal-find-cid","hide-footer":"","size":"lg","title":"Buscar CID"}},[_c('c-i-d-catalog',{attrs:{"onModal":true,"selectable":true},on:{"select":_vm.onInput}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }