import axios from "@/utils/axios.js";

const storeAtendimento = async (data) => {
  try {
    return (await axios.post("/atendimentos/store", data)).data;
  } catch (error) {
    console.log(error);
  }
};

const getAtendimento = async(filters)=>{
    try {   
        return (await axios.post('/atendimentos/get', {...filters})).data;
    }
    catch (error) {
        console.log(error)
    }
}
export default {
  storeAtendimento,
  getAtendimento
};
