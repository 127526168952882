var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"items":_vm.detalhes,"fields":[
      {
        key: 'id',
        label: 'Venda',
      },
      {
        key: 'paciente',
        label: 'Paciente',
        class: 'text-left',
      },
      {
        key: 'exame',
        label: 'Exame',
        class: 'text-left',
      },
      {
        key: 'vl_total',
        label: 'Valor',
      },
    ],"small":""},scopedSlots:_vm._u([{key:"cell(exame)",fn:function(row){return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(row.item.exame)+" ")])]}},{key:"cell(paciente)",fn:function(row){return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(row.item.paciente)+" ")])]}},{key:"cell(vl_total)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_total))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }